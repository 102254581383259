<template>
  <div>
    <p
      v-if="myMessage"
      class="text-right"
    >
      <user-avatar
        :user-id="message.author"
        :left="false"
      />
      {{ message.content }}
    </p>
    <p v-if="!myMessage && isAdmin">
      <user-avatar
        :user-id="message.author"
        :left="true"
      />
      <rich-text :content-string="message.content" />
    </p>
    <p v-if="!myMessage && !isAdmin">
      <user-avatar
        :user-id="message.author"
        :left="true"
      />
      {{ message.content }}
    </p>
    <div
      style="clear: both;"
      class="mb-3"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../../config';
import RichText from './RichText.vue';

import UserAvatar from './UserAvatar.vue';

export default {
  name: 'Message',
  components: {
    UserAvatar,
    RichText,
  },
  props: {
    message: {
      type: Object,
      required: true,
      default() {
        return {
          _id: '',
          content: '',
          author: '',
        };
      },
    },
    me: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['getUserID']),
    myMessage() {
      return this.me === this.message.author;
    },
    isAdmin() {
      return (this.message && this.message.author && this.message.author === config.wfmAccount);
    },
  },
  mounted() {
  },
};
</script>

<style>

</style>
