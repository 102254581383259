<template>
  <v-app>
    <v-container>
      <Header />
      <router-view :key="$route.fullPath" />
      <Footer />
    </v-container>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    // Stuff here.
    Header,
    Footer,
  },
};
</script>
<style>
.monospace {
  font-family: monospace;
}
</style>
