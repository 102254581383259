<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`Latest events`"
    />
    <v-row>
      <v-col>
        <event-list />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import EventList from '../components/listViews/EventList.vue';

export default {
  name: 'Home',
  components: { EventList },
  computed: mapGetters(['getLoggedIn']),
  methods: {

  },
};
</script>
