<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`Home page of Write for me!`"
    />

    <v-row>
      <v-col>
        <h2 class="headline">
          You did it!
        </h2>
      </v-col>
    </v-row>
    <v-row v-if="solution.task.rewardImmediately">
      <v-col
        v-if="solution.task.reward.length>0"
        class="mb-0 pb-0"
      >
        <v-row>
          <v-col v-if="solution.task.creator">
            <v-sheet
              class="pa-3"
              elevation="3"
              color="primary"
            >
              <h4 class="title-1">
                {{ solution.task.creator.name }} said:
              </h4>
              <hr class="mb-3">
              <rich-text :content-string="solution.task.reward" />
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="showForm">
      <v-sheet
        v-if="solution.task.creator"
        class="pa-3"
        elevation="3"
      >
        <p>
          Initially you had to write <span class="primary--text">{{ initialLinesString }}</span>,
          you made <span class="primary--text">
            {{ mistakesString }}</span> and wrote <span class="primary--text">
            {{ totalLinesString }}</span> in total.
        </p>
        <p>
          You typed <span class="primary--text">
            {{ solution.lettersPerMinute }}</span> letters per minute on average.
        </p>
        <p class="mb-0">
          Your total time was <span class="primary--text">{{ durationString }}</span>.
        </p>
      </v-sheet>
      <v-row>
        <v-col>
          <h3>Now submit your task to complete it:</h3>
        </v-col>
      </v-row>
      <v-form
        ref="replyForm"
        v-model="replyFormValid"
      >
        <v-row>
          <v-col>
            <v-text-field
              v-model="name"
              label="Your name"
              required
              :rules="rulesName"
              :disabled="getLoggedIn"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              label="Email"
              :rules="rulesEmail"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="reply"
              outlined
              auto-grow
              :label="`Your reply to ${solution.task.creator.name}:`"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="primary"
              :disabled="!replyFormValid"
              @click="submitReply"
            >
              {{ submitString }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-if="!showForm">
      <v-divider />
      <v-row>
        <v-col class="text-right headline">
          Was this the best you can do?
        </v-col>
        <v-col>
          <v-btn
            color="success"
            :to="`/task/${solution.task._id}`"
          >
            Repeat this task
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <TaskList
            list-type="userTasksRandom"
            :list-title="`More tasks by ${solution.task.creator.name}}`"
            :list-identifier="solution.task.creator._id"
            :list-length="5"
            :show-sort="false"
            :show-search="false"
            :show-pagination="false"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <TaskList
            list-title="Some other tasks to try"
            list-type="randomPublicTasks"
            :list-length="5"
            :show-sort="false"
            :show-search="false"
            :show-pagination="false"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import humanizeDuration from 'humanize-duration';
import { mapGetters } from 'vuex';
import apiClient from '../apiClient';

import RichText from '../components/divViews/RichText.vue';
import TaskList from '../components/listViews/TaskList.vue';

export default {
  name: 'Solution',
  components: {
    RichText,
    TaskList,
  },
  data() {
    return {
      name: '',
      email: '',
      reward: '',
      showForm: true,
      solution: {
        _id: '',
        task: {
          _id: '',
          creator: {
            _id: '',
            name: '',
          },
          totalLines: 0,
          reward: '',
          rewardImmediately: false,
        },
        totalTime: 0,
        totalLines: 0,
        lettersPerMinute: 0,
        mistakes: 0,
      },
      reply: '',
      replyFormValid: false,
      rulesName: [
        v => !(v.length < 1) || 'Please enter a name!',
      ],
      rulesEmail: [
        v => {
          const regEx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
          const valResult = v.match(regEx);
          let retVal = false;
          if (valResult || v.length === 0) {
            retVal = true;
          }
          return retVal || 'Please enter a valid email address!';
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getUsername', 'getEmail', 'getPendingMessage', 'getLoggedIn']),
    initialLinesString() {
      if (!this.solution.initialLines) {
        return '';
      }
      if (this.solution.initialLines === 1) {
        return `${this.solution.initialLines} line`;
      }
      return `${this.solution.initialLines} lines`;
    },
    mistakesString() {
      if (!this.solution.mistakes && this.solution.mistakes !== 0) {
        return '';
      }
      if (this.solution.mistakes === 1) {
        return `${this.solution.mistakes} mistake`;
      }
      return `${this.solution.mistakes} mistakes`;
    },
    totalLinesString() {
      if (!this.solution.totalLines) {
        return '';
      }
      if (this.solution.totalLines === 1) {
        return `${this.solution.totalLines} line`;
      }
      return `${this.solution.totalLines} lines`;
    },
    durationString() {
      return humanizeDuration(this.solution.totalTime, { maxDecimalPoints: 1 });
    },
    submitString() {
      if (this.reply.length > 0) {
        return 'Submit this task and your reply';
      }
      return 'Submit this task';
    },
    messageTask() {
      if (!this.$route.query.accessCode && this.$route.query.action && this.$route.query.action === 'submitMessage') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // Prefill user data if given:
    if (this.$store.getters.getUsername && this.$store.getters.getUsername.length > 0) {
      this.name = this.$store.getters.getUsername;
    }
    if (this.$store.getters.getEmail && this.$store.getters.getEmail.length > 0) {
      this.email = this.$store.getters.getEmail;
    }

    if (this.$route.params.solutionID) {
      if (this.messageTask) {
        const requestObject = {
          solutionID: this.$route.params.solutionID,
          pendingMessage: this.getPendingMessage,
        };
        apiClient.post('/sendPendingMessage', requestObject)
          .then(response => {
            const conversation = response.data.recipientID;
            this.$router.push({ name: 'conversation', params: { userID: conversation } });
          })
          .catch(() => {
            this.$store.commit('setSnackbar', {
              show: true,
              text: 'Sorry, something went wrong. Are you still online?',
              color: 'error',
            });
          });
      } else {
        const requestObject = {
          solutionID: this.$route.params.solutionID,
          accessCode: this.$route.query.accessCode,
        };
        apiClient.post('/getSolution', requestObject)
          .then(response => {
            this.solution = response.data;
          })
          .catch(() => {
            this.$store.commit('setSnackbar', {
              show: true,
              text: 'Sorry, something went wrong. Are you still online?',
              color: 'error',
            });
          });
      }
    }
  },
  methods: {
    submitReply() {
      const requestObject = {
        solutionID: this.$route.params.solutionID,
        accessCode: this.$route.query.accessCode,
        reply: this.reply,
        name: this.name,
        email: this.email,
      };
      apiClient.post('/finalizeTask', requestObject)
        .then(response => {
          if (response.data.reward && response.data.reward.length > 0) {
            this.solution.task.reward = response.data.reward;
          } else {
            this.solution.task.reward = '';
          }
          this.solution.task.rewardImmediately = true;
          this.showForm = false;
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Sorry, something went wrong. Are you still online?',
            color: 'error',
          });
        });
    },
  },
};
</script>
