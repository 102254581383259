<template>
  <div>
    <div v-if="solutionList && solutionList.length > 0 || search.length > 0">
      <v-card
        v-if="showSort || showSearch"
        class="mb-3"
        elevation="3"
      >
        <v-card-title>
          <v-row>
            <v-col v-if="showSort">
              <v-select
                v-model="sortModel"
                dense
                :items="sortArray"
                menu-props="auto"
                label="Select"
                hide-details
                single-line
                :append-icon="sortAsc ? 'mdi-sort-ascending' : 'mdi-sort-descending'"
                @click:append="switchAsc()"
                @change="switchOrder()"
              />
            </v-col>
            <v-col v-if="showSearch">
              <v-text-field
                v-model="search"
                dense
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
                @input="performSearch"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
      </v-card>

      <SolutionTeaser
        v-for="currentSolution in solutionList"
        :key="currentSolution._id"
        :my-solution="currentSolution"
        :show-details="showDetails"
        :show-task-info="showTaskInfo"
      />
      <v-pagination
        v-if="totalPages > 1 && showPagination"
        v-model="page"
        :length="totalPages"
        @input="switchPages"
      />
    </div>
  </div>
</template>

<script>
import apiClient from '../../apiClient';
import SolutionTeaser from '../divViews/SolutionTeaser.vue';


export default {
  name: 'SolutionList',
  components: {
    SolutionTeaser,
  },
  props: {
    listType: {
      type: String,
      required: true,
      default: '',
    },
    listIdentifier: {
      type: String,
      required: false,
      default: '',
    },
    accessCode: {
      type: String,
      required: false,
      default: '',
    },
    showSort: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    listLength: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data() {
    return {
      solutionList: [],
      totalCount: 0,
      headers: [],
      search: '',
      page: 1,
      skip: 0,
      sortArray: [],
      sortModel: 'created',
      sortAsc: false,
      showDetails: false,
      showTaskInfo: false,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil((this.totalCount) / this.listLength);
    },
  },
  mounted() {
    // Setup
    if (this.listType === 'taskSolutions') {
      this.sortArray = [
        {
          text: 'Sort by time of creation',
          value: 'created',
        },
        {
          text: 'Sort by user',
          value: 'userName',
        },
        {
          text: 'Sort by mistakes',
          value: 'mistakes',
        },
        {
          text: 'Sort by time needed',
          value: 'totalTime',
        },
      ];
    } else if (this.listType === 'detailledMySolutions') {
      this.sortArray = [
        {
          text: 'Sort by time of creation',
          value: 'created',
        },
        {
          text: 'Sort by user',
          value: 'userName',
        },
        {
          text: 'Sort by mistakes',
          value: 'mistakes',
        },
        {
          text: 'Sort by time needed',
          value: 'totalTime',
        },
      ];
      this.showDetails = true;
      this.showTaskInfo = true;
    } else if (this.listType === 'detailledTaskSolutions') {
      this.sortArray = [
        {
          text: 'Sort by time of creation',
          value: 'created',
        },
        {
          text: 'Sort by user',
          value: 'userName',
        },
        {
          text: 'Sort by mistakes',
          value: 'mistakes',
        },
        {
          text: 'Sort by time needed',
          value: 'totalTime',
        },
        {
          text: 'Sort by attempts',
          value: 'attempts',
        },
      ];
      this.showDetails = true;
      this.showTaskInfo = false;
    }

    // eslint-disable-next-line prefer-destructuring
    this.sortModel = this.sortArray[0].value;

    // Get Tasks
    this.updateData();
  },
  methods: {
    switchPages() {
      this.skip = this.listLength * (this.page - 1);
      this.updateData();
    },
    switchAsc() {
      this.resetPagination();
      this.sortAsc = !this.sortAsc;
      this.updateData();
    },
    switchOrder() {
      this.resetPagination();
      this.updateData();
    },
    performSearch() {
      this.resetPagination();
      this.updateData();
    },
    resetPagination() {
      this.page = 1;
      this.skip = 0;
    },
    updateData() {
      if (this.listType.length === 0) {
        return;
      }
      let requestObject = {};
      if (this.listType === 'taskSolutions') {
        requestObject = {
          listType: this.listType,
          task: this.listIdentifier,
          query: this.search,
          limit: this.listLength,
          skip: this.skip,
          sortBy: this.sortModel,
          sortAsc: this.sortAsc,
        };
      } else if (this.listType === 'detailledTaskSolutions') {
        requestObject = {
          listType: this.listType,
          task: this.listIdentifier,
          query: this.search,
          limit: this.listLength,
          skip: this.skip,
          sortBy: this.sortModel,
          sortAsc: this.sortAsc,
        };
      } else if (this.listType === 'detailledMySolutions') {
        requestObject = {
          listType: this.listType,
          query: this.search,
          limit: this.listLength,
          skip: this.skip,
          sortBy: this.sortModel,
          sortAsc: this.sortAsc,
        };
      }
      if (this.accessCode && this.accessCode.length > 0) {
        requestObject.accessCode = this.accessCode;
      }
      apiClient.post('adminGetSolutions', requestObject)
        .then(response => {
          this.solutionList = response.data.solutions;
          this.totalCount = response.data.totalCount;
        })
        .catch(() => {
        });
    },
  },
};
</script>
