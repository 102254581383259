<template>
  <div>
    <vue-headful :title="`Create New Task | Write for me!`" :description="`Create a new task on Write for me!`" />
    <v-row>
      <v-col>
        <h2>Create a New Task</h2>
        <v-form ref="createTaskForm" v-model="formValid">
          <v-textarea v-model="taskJson" label="Task JSON" :rules="rulesNotEmpty" required rows="10" />
          <v-btn color="primary" @click="submitTask">Create Task</v-btn>
        </v-form>
        <pre>
          <code ref="jsonTemplate">
{
  "name": "Task Name",
  "introText": "Introduction text for the task.",
  "text": "Main text of the task.",
  "reward": "Reward description.",
  "punishmentLines": 0,
  "punishmentTime": 0,
  "punishmentTimeLines": 0,
  "totalLines": 50,
  "created": "2025-11-25T14:58:00.000Z",
  "updated": "2025-11-25T14:58:00.000Z",
  "interruptionFrequency": 215,
  "useInterruptions": true,
  "interruptions": [
    "Interruption message 1.",
    "Interruption message 2."
  ],
  "rewardImmediately": true,
  "notification": true,
  "creatorId": "66f6b0bfcd28f40037169a2b",
  "protectedTask": false,
  "chineseWhisper": false,
  "sound": "no sound",
  "blindFlight": false,
  "publicTask": false,
  "loggedInOnly": false,
  "blindType": false,
  "hideCompletions": false,
  "randomInterruptions": true
}
          </code>
        </pre>
        <v-btn @click="copyToClipboard">Copy JSON to Clipboard</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from '../apiClient';

export default {
  name: 'CreateTask',
  data() {
    return {
      taskJson: '',
      formValid: false,
      rulesNotEmpty: [(v) => !!v || 'This field is required'],
    };
  },
  methods: {
    submitTask() {
      if (this.formValid) {
        try {
          const taskData = JSON.parse(this.taskJson);
          const {
            name,
            text,
            punishmentLines,
            punishmentTime,
            punishmentTimeLines,
            totalLines,
            created,
            updated,
            notification,
            reward,
            rewardImmediately,
            protectedTask,
            accessCode,
            chineseWhisper,
            sound,
            blindFlight,
            publicTask,
            blindType,
            introText,
            interruptionFrequency,
            interruptions,
            useInterruptions,
            randomInterruptions,
            loggedInOnly,
            hideCompletions,
            creatorId,
          } = taskData;

          const payload = {
            newTask: true,
            name,
            text,
            punishmentLines,
            punishmentTime,
            punishmentTimeLines,
            totalLines,
            created,
            updated,
            notification,
            reward,
            rewardImmediately,
            protectedTask,
            accessCode,
            chineseWhisper,
            sound,
            blindFlight,
            publicTask,
            blindType,
            introText,
            interruptionFrequency,
            interruptions,
            useInterruptions,
            randomInterruptions,
            loggedInOnly,
            hideCompletions,
            creatorId,
          };

          console.log('Payload being sent to /adminNewTask:', payload);

          apiClient
            .post('/adminNewTask', payload)
            .then(() => {
              console.log('Task Created Successfully');
            })
            .catch(() => {
              console.error('Error creating task');
            });
        } catch (error) {
          console.error('Invalid JSON format');
        }
      }
    },
    copyToClipboard() {
      const jsonTemplate = this.$refs.jsonTemplate.textContent;
      navigator.clipboard
        .writeText(jsonTemplate)
        .then(() => {
          console.log('JSON template copied to clipboard');
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    },
  },
};
</script>

<style scoped>
/* Add any styles specific to the CreateTask component here */
</style>
