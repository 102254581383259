<template>
  <div>
    <v-card
      class="mb-5 pt-3"
      elevation="3"
    >
      <v-card-title class="font-weight-regular mb-0 pb-0 mt-0 pt-0">
        <v-row class="mb-0 pb-0">
          <v-col>
            <user-button
              v-if="myEvent.user"
              :user-i-d="myEvent.user"
            />
            <br>
            <v-btn
              text
              :to="`/ipa/${myEvent.ipa}`"
              class="caption"
            >
              {{ myEvent.ipa }}
            </v-btn>
          </v-col>

          <v-col>
            <time-thingy
              :time="myEvent.timestamp"
              text-before=""
              text-after=" ago."
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mb-0 pb-0 mt-0 pt-0">
        <v-row>
          <v-col>
            <p class="title">
              {{ myEvent.description }}
            </p>
          </v-col>
          <v-col>
            <p class="title">
              {{ myEvent.content }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <user-button
              v-if="myEvent.recipient && myEvent.recipient.length >0"
              :user-i-d="myEvent.recipient"
            />
          </v-col>
          <v-col>
            <v-btn
              v-if="myEvent.task && myEvent.task.length >0"
              :to="`/task/${myEvent.task}`"
            >
              Task
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-if="myEvent.solution && myEvent.solution.length >0"
              :to="`/completions/${myEvent.solution.task}`"
            >
              Solution
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import UserButton from './UserButton.vue';
import TimeThingy from '../technical/TimeThingy.vue';

export default {
  name: 'EventTeaser',
  components: {
    UserButton,
    TimeThingy,
  },
  props: {
    myEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      totalCount: 0,
    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style>

</style>
