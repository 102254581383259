<template>
  <div>
    <v-card
      class="mb-3"
      elevation="3"
      :to="`/reports/${myReport._id}`"
    >
      <v-card-title class="font-weight-regular mb-0 pb-0 mt-0 pt-0">
        <v-row class="mb-0 pb-0">
          <v-col>
            <user-button
              v-if="myReport.author"
              :user-i-d="myReport.author"
            />
          </v-col>

          <v-col>
            <time-thingy
              :time="myReport.timestamp"
              text-before=""
              text-after=" ago."
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mb-0 pb-0 mt-0 pt-0">
        <v-row>
          <v-col>
            <p class="title">
              {{ myReport.type }}: {{ myReport.reference }}
            </p>
          </v-col>
          <v-col>
            <p class="title">
              {{ myReport.content }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <user-button
              v-if="myReport.recipient && myReport.recipient.length >0"
              :user-i-d="myReport.recipient"
            />
          </v-col>
          <v-col>
            <v-btn
              v-if="myReport.task && myReport.task.length >0"
              :to="`/task/${myReport.task}`"
            >
              Task
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-if="myReport.solution && myReport.solution.length >0"
              :to="`/completions/${myReport.solution.task}`"
            >
              Solution
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        Actions go here.
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import UserButton from './UserButton.vue';
import TimeThingy from '../technical/TimeThingy.vue';

export default {
  name: 'EventTeaser',
  components: {
    UserButton,
    TimeThingy,
  },
  props: {
    myReport: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      totalCount: 0,
    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style>

</style>
