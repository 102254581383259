<template>
  <div>
    <vue-headful :title="`${pageTitle} | Write for me!`" :description="`Edit task on Write for me!`" />
    <v-row>
      <v-col>
        <p class="display-1">Edit task: {{ taskName }}</p>
      </v-col>
      <v-col class="text-right">
        <v-btn :to="`/do-task/${taskID}`"> Do task </v-btn>
        <v-btn :to="{ name: 'AdjustTime' }">Adjust Time</v-btn>
        <v-btn :to="{ name: 'submitTask', params: { taskID: taskID } }" color="primary"> Submit Task </v-btn>
      </v-col>
    </v-row>
    <v-form ref="taskForm" v-model="taskValid">
      <h3 class="secondary--text overline">Basics</h3>
      <v-divider />
      <v-row>
        <v-col>
          <v-text-field ref="taskName" v-model="taskName" label="Name your task" :rules="rulesNotEmpty" required />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field ref="taskLine" v-model="taskLine" label="The line to be typed" :rules="rulesNotEmpty" required />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field v-model="taskLineCounter" class="text-right" label="Number of lines" type="number" :rules="rulesLineCount" reverse="" />
        </v-col>
        <v-col class="pt-5">
          <v-btn class="mr-1" fab x-small color="primary" :disabled="taskLineCounter <= 1" @click="decreaseLineCount">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn fab x-small color="primary" :disabled="taskLineCounter >= maxNumberOfLines" @click="increaseLineCount">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-switch v-model="publicTask" label="Make this a public task, so it may be featured on the site." />
        </v-col>
      </v-row>
      <h3 class="secondary--text overline">Communication</h3>
      <v-divider />
      <v-row>
        <v-col>
          <p class="mb-0 caption grey--text">Image- and link tags must be on their own line</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3 class="secondary--text overline">Introduction</h3>
          <rich-text :content-string="introText" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="mb-0 caption grey--text">Image- and link tags must be on their own line</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3 class="secondary--text overline">Reward</h3>
          <rich-text :content-string="reward" />
          <v-switch v-model="rewardImmediately" label="Show the reward text before the user responds to me" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-switch v-model="notification" label="Send me an Email whenever someone completes this task." />
        </v-col>
      </v-row>
      <h3 class="secondary--text overline">Punishments</h3>
      <v-divider />
      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <v-text-field v-model="taskPunishmentLinesCounter" class="text-right" label="Additional lines for each mistake" type="number" :rules="rulesPunishmentLines" reverse />
        </v-col>
        <v-col class="pt-5">
          <v-btn class="mr-1" fab x-small color="primary" :disabled="taskPunishmentLinesCounter <= 0" @click="decreasePunishmentLineCount">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn fab x-small color="primary" :disabled="taskPunishmentLinesCounter >= maxNumberOfPunishmentLines" @click="increasePunishmentLineCount">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-switch v-model="punishBreaks" label="Punishment for breaks" @change="breakPunishmentChanged" />
        </v-col>
      </v-row>
      <div v-if="punishBreaks">
        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field v-model="breakPunishmentTime" class="text-right" label="After a break of this many seconds..." type="number" :rules="rulesBreakTime" reverse="" />
          </v-col>
          <v-col class="pt-5">
            <v-btn class="mr-1" fab x-small color="primary" :disabled="breakPunishmentTime <= 1" @click="decreaseBreakPunishmentTimeCount">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn fab x-small color="primary" @click="increaseBreakPunishmentTimeCount">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field v-model="breakPunishmentLines" class="text-right" label="...add this many lines." type="number" :rules="rulesBreakLines" reverse="" />
          </v-col>
          <v-col class="pt-5">
            <v-btn class="mr-1" fab x-small color="primary" :disabled="breakPunishmentLines <= 1" @click="decreaseBreakPunishmentLineCount">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn fab x-small color="primary" :disabled="breakPunishmentLines >= maxNumberOfPunishmentLines" @click="increaseBreakPunishmentLineCount">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <h3 class="secondary--text overline">Interruptions</h3>
      <v-divider />
      <v-row>
        <v-col>
          <v-switch v-model="useInterruptions" label="Use Interruptions" />
        </v-col>
      </v-row>
      <div v-if="useInterruptions">
        <v-row>
          <v-col>
            <v-slider v-model="interruptionFrequency" dense min="-300" max="-30" label="Interruption frequency" :hint="interruptionHint" @input="updateInterruptionHint" />
            <p class="pt-0 mt-0 text-right">
              {{ interruptionHint }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card v-for="(interruption, index) in interruptionArray" :key="index" class="mb-2">
              <v-card-subtitle>
                {{ interruption }}
              </v-card-subtitle>

              <v-card-actions>
                <v-spacer />
                <v-btn class="mr-2" fab x-small outlined color="error" @click="killInterruption(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn fab x-small color="primary" :disabled="index + 1 === interruptionArray.length" @click="moveInterruption(false, index)">
                  <v-icon>mdi-arrow-down-bold</v-icon>
                </v-btn>
                <v-btn fab x-small color="primary" :disabled="index === 0" @click="moveInterruption(true, index)">
                  <v-icon>mdi-arrow-up-bold</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field ref="newInterruption" v-model="newInterruption" label="Add an interruption" :rules="rulesInterruption" />
          </v-col>
          <v-col>
            <v-btn outlined color="primary" :disabled="newInterruption.length === 0" @click="addInterruption">
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch v-model="randomInterruptions" label="Randomize interruptions" />
          </v-col>
        </v-row>
      </div>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>Special settings</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-switch v-model="chineseWhisper" label="Chinese Whisper" hint="For every mistake, one letter will be randomized." persistent-hint />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch v-model="blindFlight" label="Blind flight" hint="The number of sentences and the punishment options are hidden while typing. Also, there are no statistics except a progress bar." persistent-hint />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch v-model="blindType" label="Blind typing" hint="The typed text is hidden, there will be a placeholder text indicating when a line is complete or if there was an error." persistent-hint />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select v-model="errorSound" hint="Error sound" dense :items="soundArray" menu-props="auto" label="Select" single-line />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="accessCode" label="Access code" hint="If this is a private task, protect it with an access code." :type="showAccessCode ? 'text' : 'password'" :append-icon="showAccessCode ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showAccessCode = !showAccessCode" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </div>
</template>

<script>
// @ is an alias to /src
import apiClient from '../apiClient';
import tools from '../tools';
import RichText from '../components/divViews/RichText.vue';

export default {
  name: 'NewTask',
  components: {
    RichText,
  },
  data() {
    return {
      pageTitle: 'Edit Task',
      taskHeader: '',
      submitButtonText: 'Create this task',
      successText: 'Sorry, something went wrong. Please check that you are online and try again!',
      taskID: '',
      newTask: true,
      taskValid: false,
      taskLineCounter: 7,
      taskPunishmentLinesCounter: 0,
      taskName: '',
      taskLine: '',
      introText: '',
      reward: '',
      publicTask: false,
      notification: true,
      rewardImmediately: false,
      maxNumberOfLines: 9999,
      maxNumberOfPunishmentLines: 23,
      punishBreaks: false,
      breakPunishmentTime: 0,
      breakPunishmentLines: 0,
      chineseWhisper: false,
      blindFlight: false,
      blindType: false,
      accessCode: '',
      showAccessCode: false,
      useInterruptions: false,
      randomInterruptions: true,
      interruptionFrequency: -300,
      interruptionHint: 'rarely',
      errorSound: 'no sound',
      soundArray: [
        {
          text: 'No error sound',
          value: 'no sound',
        },
        {
          text: 'Buzzer',
          value: 'buzz',
        },
        {
          text: 'Explosion',
          value: 'explosion',
        },
        {
          text: 'Cash register',
          value: 'cashregister',
        },
        {
          text: 'Computer',
          value: 'computer',
        },
        {
          text: 'Christmas bells',
          value: 'bells',
        },
      ],
      interruptionArray: [],
      newInterruption: '',
      deleteTask: false,
      rulesNotEmpty: [(v) => v.length > 0 || 'Cannot be empty.'],
      rulesLineCount: [(v) => v >= 1 || 'Hey, let them type at least one line!', (v) => v <= this.maxNumberOfLines || "Sorry, that's too much"],
      rulesPunishmentLines: [(v) => v >= 0 || 'Sorry, only 0 or more allowed.', (v) => v <= this.maxNumberOfPunishmentLines || "Sorry, that's too much"],
      rulesBreakTime: [
        (v) => {
          let valid = true;
          if (this.punishBreaks && v <= 0) {
            valid = false;
          }
          return valid || 'At least one second!';
        },
      ],
      rulesBreakLines: [
        (v) => {
          let valid = true;
          if (this.punishBreaks && v <= 0) {
            valid = false;
          }
          return valid || 'At least one line!';
        },
        (v) => {
          let valid = true;
          if (this.punishBreaks && v > this.maxNumberOfPunishmentLines) {
            valid = false;
          }
          return valid || "Sorry, that's too much.";
        },
      ],
      rulesInterruption: [() => !this.useInterruptions || this.interruptionArray.length > 0 || 'If you want to use interruptions, add at least one!'],
      currentTaskID: '123', // Example task ID, replace with actual logic
    };
  },

  mounted() {
    // Get complete task if this is an edit task screen:
    if (this.$route.params.taskID && this.$route.params.taskID.length > 0) {
      this.newTask = false;
      this.taskID = this.$route.params.taskID;
      const requestObject = {
        taskID: this.taskID,
      };
      apiClient
        .post('adminGetCompleteTask', requestObject)
        .then((response) => {
          // Populating data:
          this.taskLineCounter = response.data.totalLines;
          this.taskPunishmentLinesCounter = response.data.punishmentLines;
          this.taskName = response.data.name;
          this.taskLine = response.data.text;
          this.introText = response.data.introText;
          this.reward = response.data.reward;
          this.publicTask = response.data.publicTask;
          this.notification = response.data.notification;
          this.rewardImmediately = response.data.rewardImmediately;
          this.breakPunishmentTime = response.data.punishmentTime;
          this.breakPunishmentLines = response.data.punishmentTimeLines;
          this.chineseWhisper = response.data.chineseWhisper;
          this.blindFlight = response.data.blindFlight;
          this.blindType = response.data.blindType;
          this.accessCode = response.data.accessCode;
          this.useInterruptions = response.data.useInterruptions;
          this.randomInterruptions = response.data.randomInterruptions;
          this.interruptionFrequency = response.data.interruptionFrequency * -1;
          this.errorSound = response.data.sound;
          this.interruptionArray = response.data.interruptions;

          this.pageTitle = `Edit ${this.taskName}`;
          this.submitButtonText = 'Save changes';
          this.successText = 'Task updated successfully!';
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Sorry, something went wrong. Please check that you are online and try again!',
            color: 'error',
          });
        });
    } else {
      this.$router.push('/tasks');
    }
  },
  methods: {
    increaseLineCount() {
      this.taskLineCounter = Number(this.taskLineCounter) + 1;
      if (this.taskLineCounter > this.maxNumberOfLines) {
        this.taskLineCounter = this.maxNumberOfLines;
      }
    },
    decreaseLineCount() {
      this.taskLineCounter = Number(this.taskLineCounter) - 1;
      if (this.taskLineCounter < 1) {
        this.taskLineCounter = 1;
      }
    },
    increasePunishmentLineCount() {
      this.taskPunishmentLinesCounter = Number(this.taskPunishmentLinesCounter) + 1;
      if (this.taskPunishmentLinesCounter > this.maxNumberOfPunishmentLines) {
        this.taskPunishmentLinesCounter = this.maxNumberOfPunishmentLines;
      }
    },
    decreasePunishmentLineCount() {
      this.taskPunishmentLinesCounter = Number(this.taskPunishmentLinesCounter) - 1;
      if (this.taskPunishmentLinesCounter < 0) {
        this.taskPunishmentLinesCounter = 0;
      }
    },
    increaseBreakPunishmentTimeCount() {
      this.breakPunishmentTime = Number(this.breakPunishmentTime) + 1;
    },
    decreaseBreakPunishmentTimeCount() {
      this.breakPunishmentTime = Number(this.breakPunishmentTime) - 1;
      if (this.breakPunishmentTime < 0) {
        this.breakPunishmentTime = 1;
      }
    },
    increaseBreakPunishmentLineCount() {
      this.breakPunishmentLines = Number(this.breakPunishmentLines) + 1;
      if (this.breakPunishmentLines > this.maxNumberOfPunishmentLines) {
        this.breakPunishmentLines = this.maxNumberOfPunishmentLines;
      }
    },
    decreaseBreakPunishmentLineCount() {
      this.breakPunishmentLines = Number(this.breakPunishmentLines) - 1;
      if (this.breakPunishmentLines < 0) {
        this.breakPunishmentLines = 1;
      }
    },
    breakPunishmentChanged() {
      if (!this.punishBreaks) {
        this.breakPunishmentTime = 0;
        this.breakPunishmentLines = 0;
      } else {
        this.breakPunishmentTime = 10;
        this.breakPunishmentLines = 1;
      }
    },
    updateInterruptionHint() {
      if (this.interruptionFrequency === -30) {
        this.interruptionHint = 'all the Time!';
      } else if (this.interruptionFrequency < -250) {
        this.interruptionHint = 'rarely';
      } else if (this.interruptionFrequency < -150) {
        this.interruptionHint = 'sometimes';
      } else if (this.interruptionFrequency < -100) {
        this.interruptionHint = 'often';
      }
    },
    addInterruption() {
      if (this.newInterruption.length > 0) {
        this.interruptionArray.push(this.newInterruption);
        this.newInterruption = '';
      }
    },
    killInterruption(index) {
      this.interruptionArray.splice(index, 1);
    },
    moveInterruption(up, index) {
      const from = index;
      let to = index;
      if (up) {
        to -= 1;
      } else {
        to += 1;
      }
      const parking = this.interruptionArray.splice(from, 1)[0];
      this.interruptionArray.splice(to, 0, parking);
    },
    insertImage(intro) {
      if (intro) {
        this.introText = tools.insertImageTagInElement(this.introText, document.getElementById('introText'));
      } else {
        this.reward = tools.insertImageTagInElement(this.reward, document.getElementById('rewardText'));
      }
    },
    insertLink(intro) {
      if (intro) {
        this.introText = tools.insertLinkTagInElement(this.introText, document.getElementById('introText'));
      } else {
        this.reward = tools.insertLinkTagInElement(this.reward, document.getElementById('rewardText'));
      }
    },
    doTask() {
      // Logic for doing the task
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
