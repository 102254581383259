<template>
  <div sticky-container>
    <v-overlay v-if="showIntroText" opacity="1">
      <div id="scrollOverlay" class="introOverlay">
        <v-row>
          <v-col>
            <UserButton :user="task.creator" />
            <v-divider class="mb-3 mt-1" />
            <rich-text :content-string="task.introText" />
            <v-btn color="primary" block @click="dismissIntroText"> Start the task </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-overlay>

    <v-overlay :value="accessCodeFormShown" opacity="1">
      <v-row>
        <v-col>
          <p>{{ accessCodeHint }}</p>
        </v-col>
      </v-row>
      <v-form ref="accessCodeForm" v-model="accessCodeValid">
        <v-row>
          <v-col>
            <v-text-field v-model="accessCode" label="Access code" :type="showAccessCode ? 'text' : 'password'" :append-icon="showAccessCode ? 'mdi-eye' : 'mdi-eye-off'" required :rules="rulesAccessCode" @click:append="showAccessCode = !showAccessCode" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn outlined class="mr-4" @click="cancelAccessCode"> Cancel </v-btn>

            <v-btn color="primary" :disabled="!accessCodeValid" @click="submitAccessCode"> Submit </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-overlay>

    <v-form ref="taskDataForm" v-model="taskDataValid">
      <v-row>
        <v-col>
          <v-text-field v-model="taskData.totalTime" label="Total Time (ms)" type="number" :readonly="true" />
          <p>Total Time: {{ humanReadableTotalTime }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="taskData.totalLines" label="Total Lines" type="number" :readonly="true" />
        </v-col>
        <v-col>
          <v-text-field v-model="taskData.lettersPerMinute" label="Letters Per Minute" type="number" required />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="taskData.mistakes" label="Mistakes" type="number" @input="handleMistakesInput" />
          <v-btn :disabled="taskData.mistakes <= 0" @click="decreaseMistake">-</v-btn>
          <v-btn @click="increaseMistake">+</v-btn>
        </v-col>
        <v-col>
          <v-btn @click="addTimePunishment">Add Time Punishment</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn color="primary" :disabled="!taskDataValid" @click="submitTaskData">Submit Task Data</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <vue-headful :title="`${task.name || 'Task'} | Write for me!`" :description="`Home page of Write for me!`" />
    <div v-if="!showIntroText">
      <v-row>
        <v-col>
          <h2 class="headline">
            {{ task.name }}
          </h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="title secondary--text"> You will type the line: </v-col>
      </v-row>
      <div class="grey darken-3 pa-3">
        {{ task.text }}
      </div>
      <v-row v-if="task.blindFlight">
        <v-col class="title text-right secondary--text"> until you are done. </v-col>
      </v-row>
      <v-row v-if="!task.blindFlight">
        <v-col class="title text-right secondary--text"> {{ realLinesToType }} times correctly. </v-col>
      </v-row>

      <v-row v-if="task.interruptions && task.interruptions.length > 0">
        <v-col>
          <v-carousel>
            <v-carousel-item v-for="(interruption, index) in task.interruptions" :key="index">
              <rich-text :content-string="interruption" :is-interruption="true" />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row v-if="task.creator" class="text-right">
        <v-col>
          <span class="subtitle-1">You're typing this task for </span>
          <UserButton :user-i-d="task.creator._id" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <SolutionList list-type="taskSolutions" :list-identifier="$route.params.taskID" :list-length="5" :show-search="false" :access-code="accessCodeComputed" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import humanizeDuration from 'humanize-duration';
import emojiRegex from 'emoji-regex';

import { mapGetters } from 'vuex';
import apiClient from '../apiClient';

import RichText from '../components/divViews/RichText.vue';
import UserButton from '../components/divViews/UserButton.vue';
import SolutionList from '../components/listViews/SolutionListView.vue';
import tools from '../tools';

export default {
  name: 'Task',
  components: {
    RichText,
    UserButton,
    SolutionList,
  },
  data() {
    return {
      auto: false,
      autoTimeout: null,
      taskCounted: false,
      typingSpeed: 250,
      attempts: 0,
      errorSound: false,
      task: {},
      typingStarted: false,
      taskFinished: false,
      currentLine: '',
      letterCounter: 0,
      completedLines: [],
      charInLine: 0,
      correctLines: 0,
      realLinesToType: 0,
      mistakes: 0,
      startTime: null,
      lastKeyTime: null,
      lastTimePunishmentTime: null,
      currentTime: null,
      lineStartTime: null,

      accessCodeFormShown: false,
      accessCodeHint: '',
      showAccessCode: false,
      accessCodeValid: false,
      accessCode: '',
      errorOverlay: false,
      pauseTyping: false,
      interruptionShown: false,
      currentInterruption: '',
      lettersSinceLastInterruption: 0,
      lettersForNextInterruption: 0,
      interruptionIndex: 0,
      errorHowl: null,
      waitHowl: null,
      showIntroText: false,
      introTextArray: [],
      rulesAccessCode: [
        (v) => {
          const regEx = /^[0-9a-zA-Z]+$/;
          const valResult = v.match(regEx);
          let retVal = false;
          if (valResult) {
            retVal = true;
          }
          return retVal || 'Please use alphanumeric characters only!';
        },
        (v) => !(v.length < 1) || "The access code can't be empty!",
      ],
      taskData: {
        totalTime: 0,
        totalLines: 0,
        lettersPerMinute: Math.floor(Math.random() * (340 - 220 + 1)) + 220,
        lines: '',
        mistakes: 0,
        attempts: 0,
      },
      taskDataValid: false,
    };
  },
  computed: {
    ...mapGetters(['getAttempts']),
    completeTimeString() {
      const difference = this.currentTime - this.startTime;
      return humanizeDuration(difference, { round: true });
    },
    lastKeyTimeString() {
      const difference = this.currentTime - this.lastKeyTime;
      return humanizeDuration(difference, { round: true });
    },
    lettersPerMinute() {
      const difference = this.currentTime - this.startTime;
      if (difference > 0) {
        return Math.round(this.letterCounter / (difference / 1000 / 60));
      }
      return 0;
    },
    progressPercent() {
      if (this.task && this.task.text) {
        const lettersToType = this.realLinesToType * this.task.text.length;
        if (lettersToType > 0) {
          return (this.letterCounter / lettersToType) * 100;
        }
      }
      return 0;
    },
    accessCodeComputed() {
      if (this.$route.query.accessCode && this.$route.query.accessCode.length > 0) {
        return this.$route.query.accessCode;
      }
      return '';
    },
    messageTask() {
      if (!this.$route.query.accessCode && this.$route.query.action && this.$route.query.action === 'submitMessage') {
        return true;
      }
      return false;
    },
    humanReadableTotalTime() {
      return humanizeDuration(this.taskData.totalTime, { round: true });
    },
  },
  watch: {
    'taskData.mistakes': function () {
      this.recalculateLinesAndTime();
    },
    'taskData.lettersPerMinute': function () {
      this.calculateTotalTime();
    },
  },
  created() {},
  beforeDestroy() {},
  mounted() {
    if (this.$route.params.taskID) {
      let requestObject = {
        taskID: this.$route.params.taskID,
      };
      if (this.$route.query.accessCode && this.$route.query.accessCode.length > 0) {
        requestObject.accessCode = this.$route.query.accessCode;
      }
      apiClient
        .post('/taskDetail', requestObject)
        .then((response) => {
          this.task = response.data;
          this.realLinesToType = this.task.totalLines;
          this.taskData.totalLines = this.realLinesToType;
          // Prepare intro text:
          if (this.task.introText && this.task.introText.length > 0 && !this.messageTask) {
            this.showIntroText = true;
            this.pauseTyping = true;
          }
          this.calculateTotalTime();
        })
        .catch((err) => {
          const responseCode = err.response.status;
          if (responseCode === 411) {
            // Wrong code
            this.accessCodeFormShown = true;
            this.accessCodeHint = 'The access code you entered was wrong. Please try again!';
          } else if (responseCode === 401) {
            // Code needed
            this.accessCodeFormShown = true;
            this.accessCodeHint = 'This task is protected by an access code. Please enter it here.';
          } else {
            this.$store.commit('setSnackbar', {
              show: true,
              text: 'Sorry, this task could not be found.',
              color: 'error',
            });
            this.$router.push('/404');
          }
        });
    } else {
      // Check access code responses.
      this.$router.push('/404');
    }
    // Initialize total lines and lines based on task data
    this.taskData.lines = this.task.text || '';
    this.calculateTotalTime();
  },
  methods: {
    preloadImages() {
      const images = [];
      this.task.interruptions.forEach((interruption) => {
        const imgItems = tools.getRestrictedParagraphs(interruption).filter((item) => item.type === 'img');

        imgItems.forEach((item) => {
          if (item.url) {
            images.push(item.url);
          }
        });
      });

      images.forEach((imageUrl) => {
        const img = new Image();
        img.src = imageUrl;
      });
    },
    completeLine(correct) {
      // Add timestamp for new line
      const now = Date.now();
      const timeForLine = now - this.lineStartTime;

      const newLineObject = {
        correct,
        text: this.currentLine,
        timeForLine,
        lpt: this.charInLine / timeForLine,
      };
      this.completedLines.push(newLineObject);
      this.charInLine = 0;
      this.currentLine = '';
      this.lineStartTime = Date.now();
      if (correct) {
        this.correctLines += 1;
        if (this.correctLines === this.realLinesToType) {
          this.handleTaskFinished();
        }
      }
    },

    resetInterruptionsCounter() {
      this.lettersForNextInterruption = this.task.interruptionFrequency * 0.75 + Math.floor(Math.random() * (this.task.interruptionFrequency * 0.5));
      this.lettersSinceLastInterruption = 0;
    },
    spawnInterruption() {
      this.waitHowl.play();
      // Which interruption to show
      if (this.task.randomInterruptions) {
        this.currentInterruption = this.task.interruptions[Math.floor(Math.random() * this.task.interruptions.length)];
      } else {
        if (this.interruptionIndex >= this.task.interruptions.length) {
          this.interruptionIndex = 0;
        }
        this.currentInterruption = this.task.interruptions[this.interruptionIndex];
        this.interruptionIndex += 1;
      }
      // Interruption time
      // Filter out img types and join all text types into a single string.
      const textContent = tools
        .getRestrictedParagraphs(this.currentInterruption)
        .filter((item) => item.type === 'text')
        .map((item) => item.text)
        .join('');

      // Determine interruption time based on the length of the text content.
      let interruptionTime = textContent.length * 75;

      if (this.task.punishmentTime && this.task.punishmentTime > 0 && interruptionTime >= this.task.punishmentTime * 1000) {
        interruptionTime = this.task.punishmentTime * 900;
      }

      if (interruptionTime < 1000) {
        interruptionTime = 1000;
      }
      if (interruptionTime > 14000) {
        interruptionTime = 14000;
      }
      this.pauseTyping = true;
      this.interruptionShown = true;
      this.resetInterruptionsCounter();
    },
    submitAccessCode() {
      this.$router.push({ name: 'submitTask', params: { taskID: this.$route.params.taskID }, query: { accessCode: this.accessCode } });
    },
    cancelAccessCode() {
      this.$router.push('//');
    },
    dismissIntroText() {
      this.showIntroText = false;
      this.pauseTyping = false;
    },
    getAttemptsFromStore() {
      return this.getAttempts(this.task._id);
    },

    doChineseWhisper() {
      if (!this.task.chineseWhisper) {
        return;
      }
      // Buchstabe austauschen
      const textArray = Array.from(this.task.text); // To handle emojis
      const charIndexToChange = Math.floor(Math.random() * textArray.length);
      let newChar;

      const character = textArray[charIndexToChange];

      // Check if the character is a letter
      if (/[a-z]/.test(character)) {
        newChar = this.getRandomChar('lowerLetter');
      } else if (/[A-Z]/.test(character)) {
        newChar = this.getRandomChar('upperLetter');
      } else if (emojiRegex().test(character)) {
        newChar = this.getRandomChar(Math.random() < 0.5 ? 'lowerLetter' : 'upperLetter');
      }

      textArray[charIndexToChange] = newChar;
      this.task.text = textArray.join('');
    },

    getRandomChar(type) {
      let pool = '';
      if (type === 'lowerLetter') {
        pool = 'abcdefghijklmnopqrstuvwxyz';
      } else if (type === 'upperLetter') {
        pool = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      } else if (type === 'symbol') {
        pool = ',.?/\\(^)![]{}*&^%$#\'"';
      }
      const arr = pool.split('');
      return arr[Math.floor(Math.random() * arr.length)];
    },

    replaceChar(origString, replaceChar, index) {
      const firstPart = origString.substr(0, index);
      const lastPart = origString.substr(index + 1);

      const newString = firstPart + replaceChar + lastPart;
      return newString;
    },

    calculateTotalTime() {
      const { totalLines, lettersPerMinute } = this.taskData;
      const lineLength = this.task.text ? this.task.text.length : 0;
      if (lettersPerMinute > 0 && lineLength > 0) {
        this.taskData.totalTime = (totalLines * lineLength * 60 * 1000) / lettersPerMinute;
      }
    },
    handleMistakesInput(event) {
      const value = parseInt(event.target.value, 10);
      this.taskData.mistakes = Number.isNaN(value) ? 0 : value;
      this.recalculateLinesAndTime();
    },
    increaseMistake() {
      this.taskData.mistakes += 1;
      this.recalculateLinesAndTime();

      // Check if chineseWhisper is active and run the function
      if (this.task.chineseWhisper) {
        this.doChineseWhisper();
      }
    },
    decreaseMistake() {
      if (this.taskData.mistakes > 0) {
        this.taskData.mistakes -= 1;
        this.recalculateLinesAndTime();
      }
    },
    recalculateLinesAndTime() {
      // Adjust total lines based on mistakes
      const baseLines = this.realLinesToType; // Assuming this is the base number of lines
      const linesPerMistake = this.task.punishmentLines || 0; // Use task.punishmentLines if available
      this.taskData.totalLines = baseLines + this.taskData.mistakes * linesPerMistake;

      // Recalculate total time
      this.calculateTotalTime();
    },
    addTimePunishment() {
      if (this.task && this.task.punishmentTime && this.task.punishmentTime > 0 && this.task.punishmentTimeLines && this.task.punishmentTimeLines > 0) {
        this.taskData.totalLines += this.task.punishmentTimeLines;
        this.calculateTotalTime();
      }
    },
    generateLineObjects() {
      const lines = [];
      const { totalLines } = this.taskData;
      const { mistakes } = this.taskData;
      const correctLines = totalLines - mistakes;

      for (let i = 0; i < totalLines; i += 1) {
        const isCorrect = i < correctLines;
        const lineLength = this.task.text.length;
        const timeForLine = isCorrect
          ? Math.random() * 2000 + 1000 // Random time between 1s and 3s for correct lines
          : Math.random() * 3000 + 2000; // Random time between 2s and 5s for incorrect lines
        const lpt = lineLength / timeForLine; // Calculate letters per time

        const lineObject = {
          correct: isCorrect,
          text: this.task.text,
          timeForLine,
          lpt,
        };

        lines.push(lineObject);
      }

      return lines;
    },
    shuffleArray(inputArray) {
      const array = [...inputArray]; // Create a shallow copy of the array
      for (let i = array.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array; // Return the shuffled array
    },
    submitTaskData() {
      if (this.taskDataValid) {
        // Generate line objects
        const completedLines = this.generateLineObjects();

        // Shuffle the completed lines before submission
        const shuffledLines = this.shuffleArray(completedLines);

        // Cap the shuffled lines at 10 entries
        const cappedLines = shuffledLines.slice(0, 10);

        // Prepare the payload with necessary attributes
        const payload = {
          totalTime: this.taskData.totalTime,
          totalLines: this.taskData.totalLines,
          lettersPerMinute: this.taskData.lettersPerMinute,
          lines: cappedLines, // Use capped lines
          taskRef: this.$route.params.taskID,
          mistakes: this.taskData.mistakes,
          attempts: this.attempts,
          finishedLine: this.task.finishedLine || '',
        };

        // Call the API endpoint
        apiClient
          .post('/commitTask', payload)
          .then((response) => {
            console.log('Task submission successful:', response.data);

            if (response.data.id) {
              const solutionID = response.data.id;
              const { accessCode } = response.data;
              this.$router.push({ name: 'solution', params: { solutionID }, query: { accessCode } });
            } else {
              this.$store.commit('setSnackbar', {
                show: true,
                text: 'Sorry, something went wrong saving your progress.',
                color: 'error',
              });
              this.$router.push('//');
            }
          })
          .catch((error) => {
            console.error('Error submitting task:', error);

            this.$store.commit('setSnackbar', {
              show: true,
              text: 'Sorry, something went wrong saving your progress.',
              color: 'error',
            });
            this.$router.push('//');
          });
      }
    },
  },
};
</script>

<style scoped>
.introOverlay {
  min-width: 400px;
  max-width: 800px;
  padding-right: 30px;
  padding-left: 30px;
}

div#scrollOverlay {
  overflow: auto;
  max-height: 90vh;
  max-width: 100vw;
}
</style>
