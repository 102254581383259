<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`Latest events`"
    />
    <spinner v-if="!isLoaded" />
    <v-row v-if="lastEvent">
      <v-col>
        <event-teaser :my-event="lastEvent" />
      </v-col>
    </v-row>
    <div v-if="isLoaded">
      <v-row wrap>
        <v-col>
          <h2 class="headline">
            {{ user.name }}
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="8"
          offset="2"
          sm="3"
          xl="2"
          offset-sm="0"
        >
          <gender-view
            :user="user"
          />
          <v-img
            aspect-ratio="1"
            :contain="false"
            :src="avatarURL"
          />
        </v-col>
        <v-col
          cols="6"
          sm="4"
          offset-lg="1"
          offset-xl="2"
        >
          <UserStats
            :show-created="true"
            :user-i-d="user._id"
          />
        </v-col>
        <v-col
          cols="6"
          sm="4"
        >
          <UserStats
            :show-created="false"
            :user-i-d="user._id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <div class="profile">
            <rich-text :content-string="user.bio" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            block
            :to="`/conversations/${user._id}`"
          >
            Conversations
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="user.twitter && user.twitter.length>0"
          cols="12"
          sm="4"
          lg="2"
        >
          <v-btn
            outlined
            block
            class="ma-2 white--text"
            target="_blank"
            :href="'https://twitter.com/'+user.twitter"
          >
            <v-icon>
              mdi-twitter
            </v-icon>
            {{ user.twitter }}
          </v-btn>
        </v-col>
        <v-col
          v-if="user.instagram && user.instagram.length>0"
          cols="12"
          sm="4"
          lg="2"
        >
          <v-btn
            outlined
            block
            class="ma-2 white--text"
            target="_blank"
            :href="'https://www.instagram.com/'+user.instagram"
          >
            <v-icon>
              mdi-instagram
            </v-icon>
            {{ user.instagram }}
          </v-btn>
        </v-col>
        <v-col
          v-if="user.url && user.url.length>0"
          cols="12"
          sm="4"
          lg="2"
        >
          <v-btn
            outlined
            block
            class="ma-2 white--text"
            target="_blank"
            :href="(user.url.indexOf('://') === -1) ? `//${user.url}`: user.url"
          >
            <v-icon>
              mdi-earth
            </v-icon>
            Website
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            outlined
            fab
            color="grey"
            @click="reportProfile"
          >
            <v-icon>mdi-biohazard</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>Email: {{ user.email }}</p>
        </v-col>
        <v-col>
          <p>Created: {{ user.created }}</p>
        </v-col>
        <v-col>
          <p>Updated: {{ user.updated }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>Contact: {{ user.contactOption }}</p>
        </v-col>
        <v-col>
          <p>with task: {{ user.contactTask }}</p>
        </v-col>
        <v-col>
          <p>migrationStep: {{ user.migrationStep }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>friends: {{ user.friends }}</p>
        </v-col>
        <v-col>
          <p>blockedUsers: {{ user.blockedUsers }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <block-element
            type="userID"
            :identifier="this.$route.params.userID"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import apiClient from '../apiClient';
import tools from '../tools';
import Spinner from '../components/technical/Spinner.vue';
import UserStats from '../components/divViews/UserStats.vue';
import GenderView from '../components/divViews/GenderPicker.vue';
import RichText from '../components/divViews/RichText.vue';
import BlockElement from '../components/divViews/BlockElement.vue';
import EventTeaser from '../components/divViews/EventTeaser.vue';


export default {
  name: 'User',
  components: {
    Spinner,
    UserStats,
    GenderView,
    RichText,
    BlockElement,
    EventTeaser,
  },
  data() {
    return {
      isLoaded: false,
      user: {},
      lastEvent: null,
    };
  },
  computed: {
    ...mapGetters(['getLoggedIn']),
    avatarURL() {
      return tools.getImageStringForUser(this.user);
    },
  },
  mounted() {
    if (this.$route.params.userID) {
      const requestObject = {
        userID: this.$route.params.userID,
      };
      apiClient.post('/adminUserDetail', requestObject)
        .then(response => {
          this.user = response.data;
          this.isLoaded = true;
        })
        .catch(() => {
        // err
        });
      apiClient.post('/adminLastIP', requestObject)
        .then(response => {
          this.lastEvent = response.data;
        })
        .catch(() => {
        // err
        });
    }
  },
  methods: {
    reportProfile() {
      const reqObject = {
        content: 'Admin report',
        type: 'profile',
        reference: this.$route.params.userID,
      };
      apiClient.post('/adminFileReport', reqObject)
        .then(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'User reported',
            color: 'success',
          });
        })
        .catch(() => {

        });
    },
  },
};
</script>
