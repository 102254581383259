<template>
  <div v-if="post">
    <rich-text
      :content-string="post.content"
    />
    <user-button :user-i-d="post.author" />
  </div>
</template>

<script>
// @ is an alias to /src
import RichText from './RichText.vue';
import UserButton from './UserButton.vue';
import apiClient from '../../apiClient';

export default {
  name: 'PostView',
  components: {
    RichText,
    UserButton,
  },
  props: {
    postID: {
      type: String,
      required: true,

    },
  },
  data() {
    return {
      post: null,
    };
  },

  mounted() {
    // Get complete task if this is an edit task screen:
    if (this.$route.params.reportID && this.$route.params.reportID.length > 0) {
      this.reportID = this.$route.params.reportID;
      const requestObject = {
        postID: this.postID,
      };
      apiClient.post('adminGetPostDetails', requestObject)
        .then(response => {
          // Populating data:
          this.post = response.data;
        });
    }
  },
  methods: {

  },
};

</script>

<style>

</style>
