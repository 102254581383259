<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`IP Information`"
    />
    <v-row>
      <v-col>
        <p class="display-1">
          IP address<br>
          {{ $route.params.ipa }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="userList.length>0">
      <v-col>
        <p>Associated users within the last 30 days </p>
        <user-button
          v-for="userID in userList"
          :key="userID._id"
          :user-i-d="userID._id"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <block-element
          type="ipa"
          :identifier="this.$route.params.ipa"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import BlockElement from '../components/divViews/BlockElement.vue';
import apiClient from '../apiClient';
import UserButton from '../components/divViews/UserButton.vue';

export default {
  name: 'Ipa',
  components: {
    BlockElement,
    UserButton,
  },
  data() {
    return {
      userList: [],
    };
  },
  computed: {

  },
  mounted() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      const requestObject = {
        ipa: this.$route.params.ipa,
      };
      apiClient.post('/adminIpEvents', requestObject)
        .then(response => {
          this.userList = response.data;
        })
        .catch(() => {
        // err
        });
    },
  },

};
</script>
