<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`Bandwidth Hogs`"
    />
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-row>
          <v-col>
            <p class="display-7">
              by user
            </p>
          </v-col>
        </v-row>
        <v-row v-if="userList.length>0">
          <v-col>
            <p
              v-for="currentUser in userList"
              :key="currentUser._id"
            >
              ({{ currentUser.count }})

              <user-button
                v-if="currentUser._id"
                :user-i-d="currentUser._id"
              />
              <span v-else>
                (anonymous)
              </span>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-row>
          <v-col>
            <p class="display-7">
              by IP
            </p>
          </v-col>
        </v-row>
        <v-row v-if="ipList.length>0">
          <v-col>
            <p
              v-for="currentIP in ipList"
              :key="currentIP._id"
            >
              ({{ currentIP.count }})
              <v-btn

                :to="`/ipa/${currentIP._id}`"
              >
                {{ currentIP._id }}
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import apiClient from '../apiClient';
import UserButton from '../components/divViews/UserButton.vue';

export default {
  name: 'Ipa',
  components: {
    UserButton,
  },
  data() {
    return {
      userList: [],
      ipList: [],
    };
  },
  computed: {

  },
  mounted() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      apiClient.post('/bandwidthHog')
        .then(response => {
          this.userList = response.data;
        })
        .catch(() => {
        // err
        });

      apiClient.post('/bandwidthHog', { useIP: true })
        .then(response => {
          this.ipList = response.data;
        })
        .catch(() => {
        // err
        });
    },
  },

};
</script>
