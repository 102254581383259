/* eslint-disable no-param-reassign */
import axios from 'axios';
import config from './config';
import store from './store';

const axiosConfig = {
  baseURL: config.baseURL,
  timeout: 30000,
};
const apiClient = axios.create(axiosConfig);

apiClient.interceptors.request.use(requestConfig => {
  const jwt = store.getters.getJWT;
  if (jwt.length > 0) {
    requestConfig.headers.Authorization = `Bearer ${jwt}`;
  }
  return requestConfig;
},
error => {
  Promise.reject(error);
});

apiClient.interceptors.response.use(responseConfig => {
  // Check for new token
  if (responseConfig.headers['x-new-token']) {
    const newToken = responseConfig.headers['x-new-token'];
    if (newToken !== 'undefined') {
      store.commit('setJWT', newToken);
    }
  }
  return responseConfig;
});

export default apiClient;
