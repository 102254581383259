<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-date-picker v-model="date" :first-day-of-week="1" :show-current="true" @input="saveDate" />
            <v-time-picker v-model="time" format="24hr" :allowed-hours="allowedHours" :allowed-minutes="allowedMinutes" @input="saveTime" />
            <v-btn color="primary" @click="saveChanges">Save Changes</v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import apiClient from '../apiClient';

export default {
  name: 'AdjustTime',
  data() {
    return {
      menu: false,
      date: '',
      time: '',
      formattedDate: '',
    };
  },
  mounted() {
    console.log('Component mounted, fetching task data...');
    const requestObject = {
      taskID: this.$route.params.taskID,
    };
    console.log('Request object:', requestObject);

    apiClient
      .post('adminGetCompleteTask', requestObject)
      .then((response) => {
        console.log('API response received:', response);
        const { created } = response.data;
        console.log('Created date from response:', created);
        this.populateDateTime(created);
      })
      .catch((error) => {
        console.error('Error fetching task data:', error);
        this.$store.commit('setSnackbar', {
          show: true,
          text: 'Failed to load task data.',
          color: 'error',
        });
      });
  },
  methods: {
    populateDateTime(created) {
      console.log('Populating date and time with:', created);
      const dateObj = moment.utc(created).local();
      this.date = dateObj.format('YYYY-MM-DD');
      this.time = dateObj.format('HH:mm');
      this.formattedDate = `${this.date} ${this.time}`;
      console.log('Formatted date:', this.formattedDate);
    },
    saveDate(val) {
      console.log('Date selected:', val);
      this.date = val;
      this.updateFormattedDate();
    },
    saveTime(val) {
      console.log('Time selected:', val);
      this.time = val;
      this.updateFormattedDate();
    },
    updateFormattedDate() {
      this.formattedDate = `${this.date} ${this.time}`;
      console.log('Updated formatted date:', this.formattedDate);
    },
    saveChanges() {
      console.log('Saving changes with date:', this.date, 'and time:', this.time);
      const localDateTime = moment.tz(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm', moment.tz.guess());
      const utcDateTime = localDateTime.utc().format();
      const requestObject = {
        taskID: this.$route.params.taskID,
        created: utcDateTime,
      };
      apiClient
        .post('adminSetTime', requestObject)
        .then(() => {
          console.log('Task time updated successfully');
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Task time updated successfully.',
            color: 'success',
          });
        })
        .catch((error) => {
          console.error('Error updating task time:', error);
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Failed to update task time.',
            color: 'error',
          });
        });
    },
    allowedHours: () => Array.from({ length: 24 }, (_, i) => i),
    allowedMinutes: () => Array.from({ length: 60 }, (_, i) => i),
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
