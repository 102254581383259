<template>
  <div>
    <vue-headful
      :title="`Login | Write for me!`"
      :description="`Latest events`"
    />
    <v-row>
      <v-col>
        <p class="display-1">
          Login
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-form
          ref="loginForm"
          v-model="loginValid"
        >
          <v-text-field
            ref="loginUsername"
            v-model="username"
            label="Username"
            :rules="rulesLoginUsername"
            required
          />

          <v-text-field
            v-model="password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            required
            :rules="rulesLoginPassword"
            @click:append="showPassword = !showPassword"
            @keyup.enter="doLogin"
          />

          <div class="text-right">
            <v-btn
              color="primary"
              :disabled="!loginValid"
              @click="doLogin"
            >
              Log in
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiClient from '../apiClient';

export default {
  name: 'Message',
  components: {

  },
  props: {

  },
  data() {
    return {
      loginValid: false,
      showPassword: false,
      username: '',
      password: '',
      rulesLoginUsername: [
        v => !(v.length < 3) || 'Your username is too short.',
      ],
      rulesLoginPassword: [
        v => v.length > 0 || 'Please enter your password.',
      ],
    };
  },
  computed: {
    ...mapGetters(['getUserID']),

  },
  mounted() {
  },
  methods: {
    doLogin() {
      if (!this.$refs.loginForm.validate()) {
        return;
      }
      apiClient.post('/login',
        {
          username: this.username,
          password: this.password,
        })
        .then(response => {
          if (response.data.success && response.data.token) {
            // Save token

            this.$store.commit('setJWT', response.data.token);
            const userObject = {
              name: response.data.username,
              admin: response.data.admin,
              id: response.data.id,
              email: response.data.email,
            };
            this.$store.commit('setUser', userObject);
            this.$router.push('/');
          }
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            text: 'An error occurred. Please check your credentials.',
            color: 'error',
          });
          this.snackbar = true;
        });
    },
  },
};
</script>

<style>

</style>
