<template>
  <div>
    <vue-headful
      :title="`Conversations | Write for me!`"
      :description="`Your conversations on Write for me!`"
    />
    <v-row>
      <v-col>
        <h2 class="headline">
          Conversations
        </h2>
      </v-col>
    </v-row>
    <v-row
      v-for="currentUser in conversations"
      :key="currentUser.latestPost"
      :to="`/conversations/${currentUser._id}`"
    >
      <v-col>
        <v-icon
          v-if="currentUser.readStatus === false"
          color="success"
        >
          mdi-alert-decagram
        </v-icon>
        <user-button
          :user-i-d="currentUser._id"
          :link-to-profile="false"
          @userButtonClicked="userSelected(currentUser._id)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import apiClient from '../apiClient';
import UserButton from '../components/divViews/UserButton.vue';

export default {
  name: 'Conversations',
  components: {
    UserButton,
  },
  data() {
    return {
      gotData: false,
      conversations: [],
    };
  },
  mounted() {
    this.updateConversations();
  },
  methods: {

    updateConversations() {
      const reqObject = {
        authorID: this.$route.params.authorID,
      };
      apiClient.post('/adminGetConversationList', reqObject)
        .then(response => {
          this.conversations = response.data;
          setTimeout(() => {
            this.updateConversations();
          }, 5000);
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Sorry, there was an error loading your conversations. Please try again later!',
            color: 'error',
          });
        });
    },
    userSelected(currentUserID) {
      this.$router.push(`/conversations/${this.$route.params.authorID}/${currentUserID}`);
    },
  },
};
</script>
