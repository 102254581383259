<template>
  <div>
    <vue-headful :title="`Write for me!`" :description="`Latest tasks`" />
    <v-row>
      <v-col>
        <TaskList list-type="latestPublicTasks" list-title="Latest public tasks" :list-length="10" show-creation-date="true" show-creator="true" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import TaskList from '../components/listViews/TaskList.vue';

export default {
  name: 'Home',
  components: {
    TaskList,
  },
  computed: mapGetters(['getLoggedIn']),
  methods: {},
};
</script>
