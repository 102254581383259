import VuexPersistence from 'vuex-persist';

import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  filter: (mutation) => mutation.type !== 'setCachedUser',
});

Vue.use(Vuex);

const getDefaultState = () => ({
  jwt: '',
  user: {
    admin: 'false',
    name: '',
    email: '',
    id: '',
    loggedIn: false,
  },
  pendingMessage: '',
  loginOverlayShown: false,
  loginOverlayShowsRegister: false,
  loginOverlayFromTask: false,
  headerBannerMessage: {
    show: false,
    text: '',
    classString: '',
  },
  snackbar: {
    text: '',
    color: '',
  },
  report: {
    shown: false,
    type: '',
    reference: '',
  },
  attempts: {},
  acceptedTerms: false,
  renderKey: '',
  redirectURL: '',
  userCache: {},
});

const state = getDefaultState();

const getters = {
  getJWT: (currentState) => currentState.jwt,
  getLoggedIn: (currentState) => currentState.user.loggedIn,
  getPendingMessage: (currentState) => currentState.pendingMessage,
  getAdmin: (currentState) => currentState.user.admin,
  getUsername: (currentState) => currentState.user.name,
  getEmail: (currentState) => currentState.user.email,
  getUserID: (currentState) => currentState.user.id,
  loginOverlayShown: (currentState) => currentState.loginOverlayShown,
  loginOverlayShowsRegister: (currentState) => currentState.loginOverlayShowsRegister,
  loginOverlayFromTask: (currentState) => currentState.loginOverlayFromTask,
  getHeaderBannerMessage: (currentState) => currentState.headerBannerMessage,
  getSnackbar: (currentState) => currentState.snackbar,
  getReport: (currentState) => currentState.report,
  getRenderKey: (currentState) => currentState.renderKey,
  getAttempts: (currentState) => (taskID) => {
    if (currentState.attempts[taskID]) {
      return currentState.attempts[taskID];
    }
    return null;
  },
  getAcceptedTerms: (currentState) => currentState.acceptedTerms,
  getRedirectURL: (currentState) => currentState.redirectURL,
  getCachedUser: (currentState) => (userID) => {
    if (currentState.userCache[userID]) {
      return currentState.userCache[userID];
    }
    return null;
  },
};
const mutations = {
  setJWT(state, newToken) {
    state.jwt = newToken;
    state.user.loggedIn = true;
  },
  setShowLogin(state, showLogin) {
    state.loginOverlayShown = showLogin;
  },
  setPendingMessage(state, newMessage) {
    state.pendingMessage = newMessage;
  },
  setUser(state, userObject) {
    state.user.id = userObject.id;
    state.user.name = userObject.name;
    state.user.admin = userObject.admin;
    state.user.email = userObject.email;
  },
  setShowRegister(state, showRegister) {
    state.loginOverlayShowsRegister = showRegister;
  },
  setLoginOverlayFromTask(state, loginOverlayFromTask) {
    state.loginOverlayFromTask = loginOverlayFromTask;
  },
  setHeaderBannerMessage(state, bannerMessage) {
    state.headerBannerMessage = bannerMessage;
  },
  setSnackbar(state, snackbarMessage) {
    state.snackbar = snackbarMessage;
  },
  setReport(state, newReport) {
    state.report = newReport;
  },
  dismissReport(state) {
    state.report = {
      shown: false,
      type: '',
      reference: '',
    };
  },
  updateAttempts(state, taskID) {
    if (state.attempts[taskID]) {
      const newCount = state.attempts[taskID] + 1;
      state.attempts[taskID] = newCount;
    } else {
      state.attempts[taskID] = 1;
    }
  },
  resetAttempts(state, taskID) {
    if (state.attempts[taskID]) {
      state.attempts[taskID] = 0;
    }
  },
  acceptTerms(state) {
    state.acceptedTerms = true;
  },
  rejectTerms(state) {
    state.acceptedTerms = false;
  },
  setRenderKey(state) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 32; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    state.renderKey = result;
  },
  setRedirectURL(state, newUrl) {
    state.redirectURL = newUrl;
  },
  clearState(state) {
    Object.assign(state, getDefaultState());
  },
  setCachedUser(state, userObject) {
    const newCache = Object.assign({}, state.userCache);
    const CACHEMINUTES = 5;
    const NOW = new Date();

    // Add if not there, otherwise set.
    Object.keys(newCache).forEach((key) => {
      const currentUser = newCache[key];
      if (currentUser.timestamp && currentUser.timestamp.getTime) {
        const oldness = NOW.getTime() - currentUser.timestamp.getTime();
        if (oldness > CACHEMINUTES * 60 * 1000) {
          // Remove cached user.
          delete newCache[key];
        }
      }
    });
    if (userObject && userObject._id) {
      _.set(newCache, userObject._id, userObject);
    }
    Vue.set(state, 'userCache', newCache);
  },
};
const actions = {};

const plugins = [vuexLocal.plugin];

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins,
});
