<template>
  <div>
    <v-row>
      <v-col>
        <v-switch
          v-model="showUnresolved"
          label="unresolved only"
          @change="toggleUnresolved"
        />
      </v-col>
    </v-row>
    <div v-if="reportList && reportList.length > 0 || search.length > 0">
      <v-card
        class="mb-3"
        elevation="3"
      >
        <v-card-title>
          <v-row>
            <v-col>
              <v-select
                v-model="sortModel"
                dense
                :items="sortArray"
                menu-props="auto"
                label="Select"
                hide-details
                single-line
                :append-icon="sortAsc ? 'mdi-sort-ascending' : 'mdi-sort-descending'"
                @click:append="switchAsc()"
                @change="switchOrder()"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                dense
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
                @input="performSearch"
              />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>

      <v-divider />

      <div>
        <report-teaser
          v-for="currentReport in reportList"
          :key="currentReport._id"
          :my-report="currentReport"
        />
        <v-row>
          <v-col>
            <v-pagination
              v-if="totalPages > 1"
              v-model="page"
              :length="totalPages"
              @input="switchPages"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '../../apiClient';
import ReportTeaser from '../divViews/ReportTeaser.vue';


export default {
  name: 'TaskList',
  components: {
    ReportTeaser,
  },
  props: {
    fixedQuery: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showUnresolved: true,
      reloadInterval: 5,
      isLoaded: false,
      reportList: [],
      totalCount: 0,
      search: '',
      page: 1,
      skip: 0,
      sortArray: [],
      sortModel: 'timestamp',
      sortAsc: false,
      listLength: 10,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil((this.totalCount) / this.listLength);
    },
  },
  mounted() {
    // Setup
    this.sortArray = [
      {
        text: 'Sort by time of creation',
        value: 'timestamp',
      },
      {
        text: 'Type',
        value: 'type',
      },
      {
        text: 'Content',
        value: 'content',
      },
    ];

    // eslint-disable-next-line prefer-destructuring
    this.sortModel = this.sortArray[0].value;

    // Get Tasks
    this.updateData();
  },
  methods: {
    switchPages() {
      this.skip = (this.listLength * (this.page - 1));
      this.updateData();
    },
    switchAsc() {
      this.resetPagination();
      this.sortAsc = !this.sortAsc;
      this.updateData();
    },
    switchOrder() {
      this.resetPagination();
      this.updateData();
    },
    performSearch() {
      this.resetPagination();
      this.updateData();
    },
    resetPagination() {
      this.page = 1;
      this.skip = 0;
    },
    updateData() {
      this.isLoaded = false;
      const requestObject = {
        query: this.search,
        fixedQuery: this.fixedQuery,
        limit: this.listLength,
        skip: this.skip,
        sortBy: this.sortModel,
        sortAsc: this.sortAsc,
        limitUnresolved: this.showUnresolved,
      };
      console.log(`${JSON.stringify(requestObject, null, 2)}`);
      apiClient.post('getReports', requestObject)
        .then(response => {
          this.isLoaded = true;
          this.reportList = response.data.reports;
          this.totalCount = response.data.totalCount;
          if (this.reload) {
            setTimeout(() => {
              this.updateData();
            }, this.reloadInterval * 1000);
          }
        })
        .catch(() => {
        });
    },
    toggleUnresolved() {
      this.updateData();
    },
    increaseReload() {
      this.reloadInterval = Number(this.reloadInterval) + 1;
    },
    decreaseReload() {
      this.reloadInterval = Number(this.reloadInterval) - 1;
      if (this.reloadInterval < 1) {
        this.reloadInterval = 1;
      }
    },
  },
};
</script>
