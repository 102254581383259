<template>
  <div>
    <div :class="left? 'float-left' : 'float-right'">
      <v-avatar :class="left? 'mr-3' : 'ml-3'">
        <img
          :src="avatarURL"
        >
      </v-avatar>
    </div>
    <p
      v-if="showName"
      class="headline pt-2"
    >
      {{ user.name }}
    </p>
  </div>
</template>

<script>
import tools from '../../tools';
import apiClient from '../../apiClient';

export default {
  name: 'UserAvatar',
  components: {

  },
  props: {
    userId: {
      type: String,
      required: true,
      default: '',
    },
    left: {
      type: Boolean,
      required: false,
      default: true,
    },
    showName: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      user: {
        photo: '',
      },
      avatarURL: '',
    };
  },
  computed: {

  },
  mounted() {
    // Get details if there is an id.
    const requestObject = {
      userID: this.userId,
    };
    apiClient.post('/adminUserDetail', requestObject)
      .then(response => {
        this.user = response.data;
        this.avatarURL = tools.getImageStringForUser(this.user, true);
      })
      .catch(() => {
        // err
      });
  },
};
</script>

<style>

</style>
