<template>
  <div>
    <v-row>
      <v-col>
        Block configuration
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="apiSwitch"
          label="API"
        />
      </v-col>

      <v-col>
        <v-text-field
          v-model="apiMinutes"
          :disabled="!apiSwitch"
          type="number"
          outlined
          label="mm"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="apiHours"
          :disabled="!apiSwitch"
          type="number"
          outlined
          label="hh"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="apiDays"
          :disabled="!apiSwitch"
          type="number"
          outlined
          label="d"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="forumSwitch"
          label="Forum"
        />
      </v-col>

      <v-col>
        <v-text-field
          v-model="forumMinutes"
          :disabled="!forumSwitch"
          type="number"
          outlined
          label="mm"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="forumHours"
          :disabled="!forumSwitch"
          type="number"
          outlined
          label="hh"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="forumDays"
          :disabled="!forumSwitch"
          type="number"
          outlined
          label="d"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="createSwitch"
          label="Create Task"
        />
      </v-col>

      <v-col>
        <v-text-field
          v-model="createMinutes"
          :disabled="!createSwitch"
          type="number"
          outlined
          label="mm"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="createHours"
          :disabled="!createSwitch"
          type="number"
          outlined
          label="hh"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="createDays"
          :disabled="!createSwitch"
          type="number"
          outlined
          label="d"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="typeSwitch"
          label="Type Task"
        />
      </v-col>

      <v-col>
        <v-text-field
          v-model="typeMinutes"
          :disabled="!typeSwitch"
          type="number"
          outlined
          label="mm"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="typeHours"
          :disabled="!typeSwitch"
          type="number"
          outlined
          label="hh"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="typeDays"
          :disabled="!typeSwitch"
          type="number"
          outlined
          label="d"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="delayTime"
          type="number"
          outlined
          label="API delay"
        />
      </v-col>

      <v-col>
        <v-text-field
          v-model="delayMinutes"
          :disabled="delayTime <= 0"
          type="number"
          outlined
          label="mm"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="delayHours"
          :disabled="delayTime <= 0"
          type="number"
          outlined
          label="hh"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="delayDays"
          :disabled="delayTime <= 0"
          type="number"
          outlined
          label="d"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          block
          color="primary"
          @click="submitChanges"
        >
          Submit changes
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiClient from '../../apiClient';

export default {
  name: 'BlockElement',
  components: {

  },
  props: {
    type: {
      type: String,
      required: true,
      default: 'ipa',
    },
    identifier: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      apiSwitch: false,
      apiMinutes: 0,
      apiHours: 0,
      apiDays: 0,
      forumSwitch: false,
      forumMinutes: 0,
      forumHours: 0,
      forumDays: 0,
      createSwitch: false,
      createMinutes: 0,
      createHours: 0,
      createDays: 0,
      typeSwitch: false,
      typeMinutes: 0,
      typeHours: 0,
      typeDays: 0,
      delayTime: 0,
      delayMinutes: 0,
      delayHours: 0,
      delayDays: 0,
    };
  },
  computed: {
    allApi() {
      if (this.apiSwitch) {
        return (this.apiMinutes * 60 + this.apiHours * 60 * 60 + this.apiDays * 60 * 60 * 24);
      }
      return 0;
    },
    allForum() {
      if (this.forumSwitch) {
        return (this.forumMinutes * 60 + this.forumHours * 60 * 60 + this.forumDays * 60 * 60 * 24);
      }
      return 0;
    },
    allCreate() {
      if (this.createSwitch) {
        return (this.createMinutes * 60 + this.createHours * 60 * 60 + this.createDays * 60 * 60 * 24);
      }
      return 0;
    },
    allType() {
      if (this.typeSwitch) {
        return (this.typeMinutes * 60 + this.typeHours * 60 * 60 + this.typeDays * 60 * 60 * 24);
      }
      return 0;
    },
    allDelay() {
      if (this.delayTime > 0) {
        return (this.delayMinutes * 60 + this.delayHours * 60 * 60 + this.delayDays * 60 * 60 * 24);
      }
      return 0;
    },
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      const reqObj = {
        blockType: this.type,
        identifier: this.identifier,
      };
      apiClient.post('adminGetBlockData', reqObj)
        .then(response => {
          if (!response.data) {
            return;
          }
          const now = new Date();
          // Set API:
          let cutOff = 0;
          if (response.data.apiCutOff) {
            const checkDate = new Date(response.data.apiCutOff);
            cutOff = checkDate.getTime() - now.getTime();
          }
          let cutObject = this.splitValue(cutOff);
          this.apiSwitch = cutObject.active;
          this.apiMinutes = cutObject.m;
          this.apiHours = cutObject.h;
          this.apiDays = cutObject.d;

          // Set Forum:
          cutOff = 0;
          if (response.data.forumCutOff) {
            const checkDate = new Date(response.data.forumCutOff);
            cutOff = checkDate.getTime() - now.getTime();
          }
          cutObject = this.splitValue(cutOff);
          this.forumSwitch = cutObject.active;
          this.forumMinutes = cutObject.m;
          this.forumHours = cutObject.h;
          this.forumDays = cutObject.d;

          // Set Create:
          cutOff = 0;
          if (response.data.createCutOff) {
            const checkDate = new Date(response.data.createCutOff);
            cutOff = checkDate.getTime() - now.getTime();
          }
          cutObject = this.splitValue(cutOff);
          this.createSwitch = cutObject.active;
          this.createMinutes = cutObject.m;
          this.createHours = cutObject.h;
          this.createDays = cutObject.d;

          // Set Type:
          cutOff = 0;
          if (response.data.typeCutOff) {
            const checkDate = new Date(response.data.typeCutOff);
            cutOff = checkDate.getTime() - now.getTime();
          }
          cutObject = this.splitValue(cutOff);
          this.typeSwitch = cutObject.active;
          this.typeMinutes = cutObject.m;
          this.typeHours = cutObject.h;
          this.typeDays = cutObject.d;

          // Set Delay:
          cutOff = 0;
          let delayTime = 0;
          if (response.data.delayCutOff) {
            const checkDate = new Date(response.data.delayCutOff);
            cutOff = checkDate.getTime() - now.getTime();
          }
          if (response.data.delayTime) {
            // eslint-disable-next-line prefer-destructuring
            delayTime = response.data.delayTime;
          }
          this.delayTime = delayTime;

          cutObject = this.splitValue(cutOff);
          this.delayMinutes = cutObject.m;
          this.delayHours = cutObject.h;
          this.delayDays = cutObject.d;
        })
        .catch(err => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: err,
            color: 'error',
          });
        });
    },
    splitValue(input) {
      let val = input / 1000;
      const retObj = {
        active: false,
        m: 0,
        h: 0,
        d: 0,
      };
      if (val > 0) {
        retObj.active = true;
        retObj.m = 1;

        // Check Days
        retObj.d = Math.round(val / (60 * 60 * 24));
        val %= (60 * 60 * 24);


        // Check Hours
        retObj.h = Math.round(val / (60 * 60));
        val %= (60 * 60);


        // Check Minutes
        retObj.m = val / 60;
      }
      return retObj;
    },
    submitChanges() {
      const reqObj = {
        blockType: this.type,
        identifier: this.identifier,
        apiValue: this.allApi,
        forumValue: this.allForum,
        createValue: this.allCreate,
        typeValue: this.allType,
        delayValue: this.allDelay,
        delayTime: this.delayTime,
      };
      console.log(`${JSON.stringify(reqObj, null, 2)}`);
      apiClient.post('adminUpdateBlockData', reqObj)
        .then(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Block values updated!',
            color: 'success',
          });
          this.refreshData();
        })
        .catch(err => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: err,
            color: 'error',
          });
        });
    },
  },
};
</script>

<style scoped>

</style>
