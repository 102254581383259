<template>
  <div v-if="report && report.type">
    <vue-headful
      :title="`View Report | Write for me!`"
      :description="`View and work on a report.`"
    />
    <v-row>
      <v-col>
        <p class="display-1">
          Report
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          <span class="overline">
            Type:
          </span><br>
          {{ report.type }}
        </p>
      </v-col>
      <v-col>
        <p>
          <span class="overline">
            Reference:
          </span><br>
          {{ report.reference }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="headline">
          Communications
        </p>
        <v-textarea
          v-model="reporterReply"
          outlined
          auto-grow
          label="Reply to reporter"
        />
        <v-textarea
          v-model="reporteeReply"
          outlined
          auto-grow
          label="Reply to reportee"
        />
      </v-col>
    </v-row>
    <v-row v-if="report.type=== 'task'">
      <v-btn
        color="success"
        block
        :to="`/task/${report.reference}`"
      >
        Review
      </v-btn><br>
      <v-col>
        <v-btn
          class="mr-2"
          @click="performAction('makePrivate')"
        >
          Make task private
        </v-btn>
        <v-btn
          class="error mr-2"
          @click="performAction('deleteTask')"
        >
          Delete task
        </v-btn>
        <v-btn
          class="error mr-2"
          @click="performAction('deleteTaskAndSolutions')"
        >
          Delete task and solutions
        </v-btn>
        <v-btn
          class="success"
          @click="performAction('dismiss')"
        >
          Dismiss
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="report.type=== 'forumPost'">
      <v-row>
        <v-col>
          <post-view :post-i-d="report.reference" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            class="error mr-2"
            @click="performAction('block')"
          >
            Block forum post
          </v-btn>
          <v-btn
            class="error mr-2"
            @click="performAction('delete')"
          >
            Delete
          </v-btn>
          <v-btn
            class="success"
            @click="performAction('dismiss')"
          >
            Dismiss
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="report.type=== 'profile'">
      <v-col>
        <v-btn
          color="success"
          block
          :to="`/user/${report.reference}`"
        >
          Review
        </v-btn><br>
        <v-btn
          color="success"
          block
          :to="`/user/${report.reference}`"
        >
          Review
        </v-btn>
        <v-btn
          class="mr-2"
          @click="performAction('deleteAvatar')"
        >
          Delete avatar
        </v-btn>
        <v-btn
          class="mr-2"
          @click="performAction('deleteBio')"
        >
          Delete Bio
        </v-btn>
        <v-btn
          class="error m3-2"
          @click="performAction('block')"
        >
          Block user
        </v-btn>
        <v-btn
          class="success"
          @click="performAction('dismiss')"
        >
          Dismiss
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <report-list :fixed-query="report.reference" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import apiClient from '../apiClient';
import ReportList from '../components/listViews/ReportList.vue';
import PostView from '../components/divViews/PostView.vue';

export default {
  name: 'Report',
  components: {
    ReportList,
    PostView,
  },
  data() {
    return {
      reportID: '',
      report: null,
      reporterReply: '',
      reporteeReply: '',
    };
  },

  mounted() {
    // Get complete task if this is an edit task screen:
    if (this.$route.params.reportID && this.$route.params.reportID.length > 0) {
      this.reportID = this.$route.params.reportID;
      const requestObject = {
        reportID: this.reportID,
      };
      apiClient.post('adminGetReportDetails', requestObject)
        .then(response => {
          // Populating data:
          this.report = response.data;
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Sorry, something went wrong. Please check that you are online and try again!',
            color: 'error',
          });
        });
    } else {
      this.$router.push('/');
    }
  },
  methods: {
    performAction(actionType) {
      const requestObject = {
        reportID: this.reportID,
        actionType,
        reporterReply: this.reporterReply,
        reporteeReply: this.reporteeReply,
      };
      apiClient.post('adminPerformReportAction', requestObject)
        .then(() => {
          // Populating data:
          this.$router.push('/');
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Sorry, something went wrong. Please check that you are online and try again!',
            color: 'error',
          });
        });
    },
  },
};

</script>

<style>

</style>
