<template>
  <div>
    <v-row>
      <v-col>
        <v-switch
          v-model="reload"
          label="Reload"
          @change="toggleReload"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="reloadInterval"
          class="text-right"
          label="seconds between reloads"
          type="number"
          reverse=""
        />
      </v-col>
      <v-col class="pt-5">
        <v-btn
          class="mr-1"
          fab
          x-small
          color="primary"
          :disabled="reloadInterval <= 1"
          @click="decreaseReload"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-btn
          fab
          x-small
          color="primary"
          @click="increaseReload"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="eventList && eventList.length > 0 || search.length > 0">
      <v-card
        class="mb-3"
        elevation="3"
      >
        <v-card-title>
          <v-row>
            <v-col>
              <v-select
                v-model="sortModel"
                dense
                :items="sortArray"
                menu-props="auto"
                label="Select"
                hide-details
                single-line
                :append-icon="sortAsc ? 'mdi-sort-ascending' : 'mdi-sort-descending'"
                @click:append="switchAsc()"
                @change="switchOrder()"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                dense
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
                @input="performSearch"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="user"
                dense
                append-icon="mdi-account"
                label="user"
                clearable
                single-line
                hide-details
                @input="performSearch"
              />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>

      <v-divider />

      <div>
        <event-teaser
          v-for="currentEvent in eventList"
          :key="currentEvent._id"
          :my-event="currentEvent"
        />
        <v-row>
          <v-col>
            <v-pagination
              v-if="totalPages > 1"
              v-model="page"
              :length="totalPages"
              @input="switchPages"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '../../apiClient';
import EventTeaser from '../divViews/EventTeaser.vue';


export default {
  name: 'TaskList',
  components: {
    EventTeaser,
  },
  props: {

  },
  data() {
    return {
      reload: false,
      reloadInterval: 5,
      isLoaded: false,
      eventList: [],
      totalCount: 0,
      search: '',
      user: '',
      page: 1,
      skip: 0,
      sortArray: [],
      sortModel: 'timestamp',
      sortAsc: false,
      listLength: 10,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil((this.totalCount) / this.listLength);
    },
  },
  mounted() {
    // Setup
    this.sortArray = [
      {
        text: 'Sort by time of creation',
        value: 'timestamp',
      },
      {
        text: 'Task name',
        value: 'name',
      },
    ];

    // eslint-disable-next-line prefer-destructuring
    this.sortModel = this.sortArray[0].value;

    // Get Tasks
    this.updateData();
  },
  methods: {
    switchPages() {
      this.skip = (this.listLength * (this.page - 1));
      this.updateData();
    },
    switchAsc() {
      this.resetPagination();
      this.sortAsc = !this.sortAsc;
      this.updateData();
    },
    switchOrder() {
      this.resetPagination();
      this.updateData();
    },
    performSearch() {
      this.resetPagination();
      this.updateData();
    },
    resetPagination() {
      this.page = 1;
      this.skip = 0;
    },
    updateData() {
      this.isLoaded = false;
      const requestObject = {
        query: this.search,
        user: this.user,
        limit: this.listLength,
        skip: this.skip,
        sortBy: this.sortModel,
        sortAsc: this.sortAsc,
      };
      apiClient.post('adminEvents', requestObject)
        .then(response => {
          this.isLoaded = true;
          this.eventList = response.data.events;
          this.totalCount = response.data.totalCount;
          if (this.reload) {
            setTimeout(() => {
              this.updateData();
            }, this.reloadInterval * 1000);
          }
        })
        .catch(() => {
        });
    },
    toggleReload() {
      this.updateData();
    },
    increaseReload() {
      this.reloadInterval = Number(this.reloadInterval) + 1;
    },
    decreaseReload() {
      this.reloadInterval = Number(this.reloadInterval) - 1;
      if (this.reloadInterval < 1) {
        this.reloadInterval = 1;
      }
    },
  },
};
</script>
