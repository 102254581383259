<template>
  <div>
    <vue-headful
      :title="`Conversation with ${recipient.name} | Write for me!`"
      :description="`Your conversation with ${recipient.name} on Write for me!`"
    />
    <v-row>
      <v-col>
        <h2 class="headline">
          Conversation view
        </h2>
      </v-col>
    </v-row>
    <v-row v-if="messages.length <= 0">
      <v-col class="text-center display-1">
        <p>No messages yet...</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <message
          v-for="currentMessage in messages"
          :key="currentMessage._id"
          :message="currentMessage"
          :me="$route.params.authorID"
        />
      </v-col>
    </v-row>
    <div id="inputField" />
  </div>
</template>

<script>
// @ is an alias to /src
import apiClient from '../apiClient';

import Message from '../components/divViews/Message.vue';

export default {
  name: 'Conversation',
  components: {
    // Stuff here.
    Message,
  },
  data() {
    return {
      recipient: {},
      messages: [],
      newMessage: '',
      contactOptionsChecked: false,
      contactOption: 0,
      contactTaskID: '',
    };
  },
  mounted() {
    this.updateData(true);
    this.autoReload();
  },
  methods: {
    updateData(scroll) {
      const requestObject = {
        recipientID: this.$route.params.recipientID,
        authorID: this.$route.params.authorID,
      };
      apiClient.post('adminGetMessages', requestObject)
        .then(response => {
          this.messages = response.data;
          if (scroll) {
            setTimeout(() => {
              // Scroll into view
              const inputField = document.getElementById('inputField');
              if (inputField) {
                inputField.scrollIntoView({ block: 'center', behavior: 'smooth' });
              }
            }, 200);
          }
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Sorry, something went wrong. Please check that you are online and try again!',
            color: 'error',
          });
        });
    },
    autoReload() {
      setTimeout(() => {
        this.updateData(false);
        setTimeout(() => {
          this.autoReload();
        }, 5000);
      }, 5000);
    },
  },
};
</script>
