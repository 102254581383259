<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`Forum list`"
    />
    <v-row>
      <v-col>
        <p class="display-1">
          Forum List
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div
          v-for="forum in forumList"
          :key="forum._id"
        >
          <v-btn :to="`/forum/${forum._id}`">
            {{ forum.name }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import apiClient from '../apiClient';

export default {
  name: 'ForumForm',
  components: {

  },
  data() {
    return {
      forumList: [],
    };
  },
  computed: {
    ...mapGetters(['getLoggedIn']),
    existingForum() {
      return (this.$route.params.forumID && this.$route.params.forumID.length > 0);
    },
  },
  mounted() {
    this.updateList();
  },
  methods: {
    updateList() {
      apiClient.post('adminGetForums')
        .then(response => {
          this.forumList = response.data;
        });
    },
  },
};
</script>
