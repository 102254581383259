<template>
  <div>
    <v-card
      class="mb-3"
      elevation="3"
    >
      <v-card-title
        class="font-weight-regular"
      >
        <v-row class="mb-0 pb-0">
          <v-col
            cols="12"
            sm="8"
          >
            <p>{{ myThread.name }}</p>
            <p>{{ myThread.author }}</p>
            <v-btn :to="`/forum/${myThread.forum}/${myThread._id}`">
              Open
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <user-button :user-i-d="myThread.author" />
            <TimeThingy
              :time="myThread.timestamp"
              text-after=" ago"
              text-before="updated "
            />
            <v-btn
              v-if="confirmDelete"
              color="error"
              @click.stop.prevent="performDelete"
            >
              Delete
            </v-btn>
            <v-btn
              v-if="!confirmDelete"
              @click.stop.prevent="confirmDelete = true"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserButton from './UserButton.vue';
import apiClient from '../../apiClient';
import TimeThingy from '../technical/TimeThingy.vue';

export default {
  name: 'ThreadTeaser',
  components: {
    UserButton,
    TimeThingy,
  },
  props: {
    myThread: {
      type: Object,
      required: true,
      default: () => ({

      }),
    },
  },
  data() {
    return {
      confirmDelete: false,
    };
  },
  computed: {
    ...mapGetters(['getUserID', 'getLoggedIn']),

  },
  mounted() {

  },
  methods: {
    performDelete() {
      const requestObject = {
        threadID: this.myThread._id,
      };
      apiClient.post('adminDeleteThread', requestObject)
        .then(() => {
          this.$router.go();
        })
        .catch(() => {
        });
    },
  },
};
</script>

<style>

</style>
