<template>
  <div>
    <v-card
      class="mb-3"
      elevation="3"
    >
      <v-card-title class="font-weight-regular mb-0">
        <v-row
          v-if="!showTaskInfo"
          class="mb-0 pb-0"
        >
          <v-col class="pt-0">
            <v-btn
              v-if="!realUser"
              class="pl-2"
              x-large
              text
              disabled
            >
              <v-avatar class="mr-3 ml-0 hidden">
                <img>
              </v-avatar>
              {{ mySolution.userName }}
            </v-btn>
            <user-button
              v-if="realUser"
              :user-i-d="mySolution.realUser._id"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="showTaskInfo"
          class="mb-0 pb-0"
        >
          <v-col>
            <p class="headline">
              {{ mySolution.task.name }}
            </p>
          </v-col>
          <v-col class="pt-0">
            by
            <user-button
              :user="mySolution.taskCreator"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="dense">
          <v-row>
            <v-col
              v-if="mySolution.email"
            >
              <p class="subtitle-1 pl-10 ml-7">
                {{ mySolution.email }}
              </p>
            </v-col>
            <v-col>
              <p class="subtitle-1">
                {{ mistakeString }}
              </p>
            </v-col>
            <v-col>
              <p class="subtitle-1">
                {{ timeString }}
              </p>
            </v-col>
          </v-row>

          <div v-if="showDetails">
            <v-row class="mb-0 pb-0">
              <v-col>
                <p class="subtitle-1">
                  {{ attemptsString }}
                </p>
              </v-col>              <v-col>
                <p class="subtitle-1">
                  {{ linesString }}
                </p>
              </v-col>
              <v-col>
                <p class="subtitle-1">
                  {{ mySolution.lettersPerMinute }} LPM
                </p>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-row
          v-if="mySolution.comment && mySolution.comment.length > 0"
        >
          <v-col>
            <p class="overline">
              Response:
            </p>
            <rich-text :content-string="commentsString" />
          </v-col>
        </v-row>
        <v-expansion-panels v-if="mySolution.lines && mySolution.lines.length > 3">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon>mdi-chart-line</v-icon>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-sparkline
                    :value="barValues"
                    type="trend"
                    :auto-line-width="true"
                    auto-draw
                    smooth="20"
                    :gradient="['#D32F2F', '#FFEE58', '#66BB6A']"
                    gradient-direction="bottom"
                    stroke-linecap="round"
                  />
                </v-col>
              </v-row>
              <p class="text-right">
                Typing speed over time
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import humanizeDuration from 'humanize-duration';

import UserButton from './UserButton.vue';
import RichText from './RichText.vue';

export default {
  name: 'SolutionTeaser',
  components: {
    UserButton,
    RichText,
  },
  props: {
    mySolution: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTaskInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    realUser() {
      return (this.mySolution.realUser && this.mySolution.realUser.name.length > 1);
    },
    mistakeString() {
      if (this.mySolution.mistakes === 1) {
        return `${this.mySolution.mistakes} mistake`;
      }
      return `${this.mySolution.mistakes} mistakes`;
    },
    attemptsString() {
      if (this.mySolution.attempts > 1) {
        return `${this.mySolution.attempts} attempts`;
      }
      return '1 attempt';
    },
    linesString() {
      if (!this.mySolution.totalLines) {
        return '';
      } if (this.mySolution.totalLines === 1) {
        return `${this.mySolution.totalLines} line`;
      }
      return `${this.mySolution.totalLines} lines`;
    },
    timeString() {
      return humanizeDuration(this.mySolution.totalTime, { maxDecimalPoints: 1 });
    },
    commentsString() {
      if (this.mySolution.comment && this.mySolution.comment.length > 0) {
        return this.mySolution.comment;
      }
      return '';
    },
    barValues() {
      if (this.mySolution.lines && this.mySolution.lines.length > 3) {
        return this.mySolution.lines.map(val => (val.lpt));
      }
      return [];
    },
  },
};
</script>

<style scoped>
.hidden{
  visibility: hidden;
}
.dense div, .dense p {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
</style>
