<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`Thread list`"
    />
    <v-row>
      <v-col>
        <p class="display-1">
          Thread List
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div
          v-for="thread in threadList"
          :key="thread._id"
        >
          <thread-teaser :my-thread="thread" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import apiClient from '../apiClient';
import ThreadTeaser from '../components/divViews/ThreadTeaser.vue';

export default {
  name: 'ForumForm',
  components: {
    ThreadTeaser,
  },
  data() {
    return {
      threadList: [],
    };
  },
  computed: {
    ...mapGetters(['getLoggedIn']),
  },
  mounted() {
    this.updateList();
  },
  methods: {
    updateList() {
      apiClient.post('adminGetThreads')
        .then(response => {
          this.threadList = response.data;
        });
    },
  },
};
</script>
