<template>
  <div>
    <vue-headful
      v-if="task.name"
      :title="`Completions for ${task.name} | Write for me!`"
      :description="`View all completions for ${task.name}.`"
    />
    <vue-headful
      v-if="!task.name"
      :title="`Completions | Write for me!`"
      :description="`View all completions for this task.`"
    />
    <v-row>
      <v-col v-if="task.name">
        <p class="display-1">
          Completions for <span
            class="primary--text"
          >
            {{ task.name }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SolutionList
          list-type="detailledTaskSolutions"
          :list-identifier="$route.params.taskID"
          :list-length="10"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import apiClient from '../apiClient';

import SolutionList from '../components/listViews/SolutionListView.vue';

export default {
  name: 'Profile',
  components: {
    SolutionList,
  },
  data() {
    return {
      task: {},
    };
  },

  mounted() {
    if (this.$route.params.taskID) {
      let requestObject = {};
      requestObject = {
        taskID: this.$route.params.taskID,
      };
      apiClient.post('adminGetTaskInfo', requestObject)
        .then(response => {
          this.task = response.data;
        })
        .catch(() => {
        });
    }
  },
  methods: {

  },
};

</script>
