<template>
  <div v-if="dataFetched">
    <!-- Existing User -->
    <v-btn v-if="currentUser && currentUser.name && currentUser.name.length > 0" :class="isAdmin ? 'pl-2 primary--text' : 'pl-2'" x-large text :to="linkToProfile ? `/user/${currentUser._id}` : ``" @click="buttonClicked">
      <v-icon v-if="icon && icon.length > 0" color="success" class="mr-1">
        {{ icon }}
      </v-icon>
      <v-avatar class="mr-3 ml-0">
        <img :src="avatarString" />
      </v-avatar>
      {{ currentUser.name }}
    </v-btn>

    <!-- No User (Deleted User) -->
    <v-btn v-else class="pl-2" x-large text disabled>
      <v-avatar class="mr-3 ml-0">
        <v-icon size="48">
          mdi-account-off
          <!-- generic mdi-icon -->
        </v-icon>
      </v-avatar>
      <span class="grey--text">deleted user</span>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import tools from '../../tools';
import config from '../../config';

export default {
  name: 'UserButton',
  components: {},
  props: {
    user: {
      type: Object,
      required: false,
      default() {
        return {
          _id: '',
          photo: '',
          name: '',
        };
      },
    },
    userID: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    linkToProfile: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      loadedUser: {},
      dataFetched: false,
    };
  },
  computed: {
    ...mapGetters(['getCachedUser']),
    currentUser() {
      return this.getCachedUser(this.loadedUser);
    },
    avatarString() {
      return tools.getImageStringForUser(this.currentUser, true);
    },
    isAdmin() {
      return this.loadedUser === config.wfmAccount;
    },
  },
  mounted() {
    if (this.user && this.user._id) {
      this.loadedUser = this.user._id;
    } else if (this.userID && this.userID.length > 0) {
      this.loadedUser = this.userID;
    }
    tools.updateUser(this.loadedUser);
    this.dataFetched = true;
  },
  methods: {
    buttonClicked() {
      if (!this.linkToProfile) {
        this.$emit('userButtonClicked');
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none !important;
}
</style>
