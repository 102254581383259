import Vue from 'vue';
import vueHeadful from 'vue-headful';
import Sticky from 'vue-sticky-directive';
// import Ads from 'vue-google-adsense';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.component('vue-headful', vueHeadful);
Vue.use(Sticky);

// Vue.use(require('vue-script2'));

// Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-8072293115588991' });


router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.getLoggedIn) {
    next({ name: 'login' });
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
