<template>
  <div v-if="userID">
    <v-card v-if="count > 0 && !small">
      <v-card-title
        v-if="showCreated"
        class="font-weight-regular"
      >
        Created
      </v-card-title>
      <v-card-title
        v-if="!showCreated"
        class="font-weight-regular"
      >
        Completed
      </v-card-title>
      <v-card-text>
        <p
          class="hidden-xs-only display-3 font-weight-bold primary--text"
        >
          {{ count }} <span class="subtitle-1">tasks</span>
        </p>
        <p
          class="d-sm-none headline primary--text"
        >
          {{ count }} <span class="body-1">tasks</span>
        </p>
        <p class="">
          Time spent:<br> {{ duration }}
        </p>
      </v-card-text>
    </v-card>
    <div v-if="small && count === 0">
      <div v-if="showCreated">
        <p>Created no tasks yet</p>
      </div>
      <div v-if="!showCreated">
        <p>Completed no tasks yet</p>
      </div>
    </div>
    <div v-if="small && count > 0">
      <div v-if="showCreated">
        <p>Created {{ count }} tasks.</p>
        <p>Time spent: {{ duration }}.</p>
      </div>
      <div v-if="!showCreated">
        <p>Completed {{ count }} tasks.</p>
        <p>Time spent: {{ duration }}.</p>
      </div>
    </div>
  </div>
</template>

<script>
import humanizeDuration from 'humanize-duration';
import apiClient from '../../apiClient';

export default {
  name: 'UserStats',
  components: {

  },
  props: {
    showCreated: {
      type: Boolean,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    userID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      duration: 'fooo',
      count: 0,
    };
  },
  mounted() {
    const requestObject = {
      userID: this.userID,
    };
    let endpoint = '';
    if (this.showCreated) {
      endpoint = 'tasksCreatedStats';
    } else {
      endpoint = 'tasksDoneStats';
    }
    apiClient.post(endpoint, requestObject)
      .then(response => {
        this.duration = humanizeDuration(response.data.sum);
        this.count = response.data.count;
      })
      .catch(() => {
      });
  },
};
</script>

<style>

</style>
