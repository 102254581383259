<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`Blocked Users`"
    />
    <v-row>
      <v-col>
        <h1>Blocked instances</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div
          v-for="(block, index) in blocks"
          :key="index"
        >
          <v-btn
            v-if="block.ipa && block.ipa.length > 0"
            block
            :to="`/ipa/${block.ipa}`"
          >
            {{ block.ipa }}
          </v-btn>
          <user-button
            v-if="block.user && block.user.length > 0"
            :user-i-d="block.user"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import apiClient from '../apiClient';
import UserButton from '../components/divViews/UserButton.vue';

export default {
  name: 'Blocks',
  components: { UserButton },
  data() {
    return {
      blocks: [],
    };
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      apiClient.post('adminGetBlocks')
        .then(response => {
          this.blocks = response.data;
        });
    },
  },
};
</script>
