<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`New Forum`"
    />
    <v-row>
      <v-col>
        <p class="display-1">
          New Forum
        </p>
      </v-col>
    </v-row>
    <v-form
      ref="forumForm"
    >
      <v-text-field
        v-model="name"
        label="Forum name"
        required
      />

      <v-text-field
        v-model="subtitle"
        label="Forum subtitle"
        required
      />

      <v-text-field
        v-model="icon"
        label="Icon string"
        required
      />

      <v-row>
        <v-col
          cols="12"
          sm="6"
          lg="3"
        >
          <v-text-field
            v-model="order"
            class="text-right"
            label="Forum order"
            type="number"
            reverse
          />
        </v-col>
        <v-col class="pt-5">
          <v-btn
            class="mr-1"
            fab
            x-small
            color="primary"
            @click="decreaseOrder"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn
            fab
            x-small
            color="primary"
            @click="increaseOrder"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div class="text-right">
        <v-btn
          color="primary"
          @click="updateForum"
        >
          Update Forum
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import apiClient from '../apiClient';

export default {
  name: 'ForumForm',
  components: {

  },
  data() {
    return {
      name: '',
      subtitle: '',
      icon: '',
      order: 0,
    };
  },
  computed: {
    ...mapGetters(['getLoggedIn']),
    existingForum() {
      return (this.$route.params.forumID && this.$route.params.forumID.length > 0);
    },
  },
  mounted() {
    if (this.existingForum) {
      const reqObj = {
        forumID: this.$route.params.forumID,
      };
      apiClient.post('adminGetForum', reqObj)
        .then(response => {
          this.name = response.data.name;
          this.subtitle = response.data.subtitle;
          this.icon = response.data.icon;
          this.order = response.data.order;
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            text: 'An error occurred.',
            color: 'error',
          });
          this.snackbar = true;
          this.$router.push('/forums');
        });
    }
  },
  methods: {
    increaseOrder() {
      this.order = Number(this.order) + 1;
    },
    decreaseOrder() {
      this.order = Number(this.order) - 1;
    },
    updateForum() {
      const reqObj = {
        name: this.name,
        subtitle: this.subtitle,
        icon: this.icon,
        order: this.order,
      };

      let url = 'adminNewForum';
      if (this.existingForum) {
        url = 'adminUpdateForum';
        reqObj.forumID = this.$route.params.forumID;
      }

      apiClient.post(url, reqObj)
        .then(() => {
          this.$store.commit('setSnackbar', {
            text: 'Forum updated',
            color: 'success',
          });
          this.snackbar = true;
          this.$router.push('/forums');
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            text: 'An error occurred. Please check your credentials.',
            color: 'error',
          });
          this.snackbar = true;
        });
    },
  },
};
</script>
