
<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`Reporting`"
    />
    <v-row>
      <v-col>
        <v-text-field
          v-model="profile"
          label="Profile"
        />
        <v-btn @click="reportProfile">
          Report Profile
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import apiClient from '../apiClient';

export default {
  name: 'ForumForm',
  components: {
  },
  data() {
    return {
      profile: '',
    };
  },
  computed: {
    ...mapGetters(['getLoggedIn']),
  },
  mounted() {
  },
  methods: {
    reportProfile() {
      const reqObject = {
        content: 'Admin report',
        type: 'profile',
        reference: this.profile,
      };
      apiClient.post('/adminFileReport', reqObject)
        .then(() => {
          this.$router.push('/');
        })
        .catch(() => {

        });
    },
  },
};
</script>
