/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */

const productionConfig = {
  baseURL: 'https://api.writeforme.org/api/admin/v1',
  wfmAccount: '5e9c8012789e8df557d97f63',
};

const developmentConfig = {
  baseURL: 'http://localhost:3333/api/admin/v1',
  wfmAccount: '5e9c8012789e8df557d97f63',
};

// eslint-disable-next-line import/no-mutable-exports
let usedConfig = productionConfig;

if (false && process.env.NODE_ENV === 'development') {
  usedConfig = developmentConfig;
}

export default usedConfig;
