<template>
  <div>
    <v-tooltip
      right
      fixed
    >
      <template v-slot:activator="{ on }">
        <p
          class="caption text-right"
        >
          <span v-on="on">
            {{ timeString }}
          </span>
        </p>
      </template>
      <span>{{ timeTooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import humanizeDuration from 'humanize-duration';
import moment from 'moment';

export default {
  name: 'TimeThingy',
  props: {
    textBefore: {
      type: String,
      required: false,
      default: '',
    },
    textAfter: {
      type: String,
      required: false,
      default: '',
    },
    time: {
      type: String,
      required: true,
    },
    showSeconds: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      now: new Date(),
      tickTimeout: null,
    };
  },
  computed: {
    timeString() {
      let durationArray = ['y', 'd', 'h', 'm'];
      if (this.showSeconds) {
        durationArray = ['y', 'd', 'h', 'm', 's'];
      }
      const myTime = Date.parse(this.time);
      const retVal = humanizeDuration((this.now - myTime), { units: durationArray, round: true });

      return `${this.textBefore}${retVal}${this.textAfter}`;
    },
    timeTooltip() {
      const myTime = Date.parse(this.time);

      return moment(myTime).format('MMMM Do YYYY, HH:mm:ss');
    },
  },
  created() {
    this.newTick();
  },
  beforeDestroy() {
    if (this.tickTimeout && this.tickTimeout.clearTimeout) {
      this.tickTimeout.clearTimeout();
    }
  },
  methods: {
    newTick() {
      this.tickTimeout = setTimeout(() => {
        this.now = new Date();
        this.newTick();
      }, 1000);
    },
  },
};
</script>
