<template>
  <div>
    <p
      v-if="line.correct"
      class="title"
    >
      {{ line.text }}<v-icon
        color="success"
        class="ml-1 pb-1"
      >
        mdi-check-bold
      </v-icon>
    </p>
    <p
      v-if="!line.correct"
      class="title"
    >
      {{ errorLine.correctPart }}<span class="error--text strikethrough">{{ errorLine.errorPart }}</span>
    </p>
  </div>
</template>

<script>

export default {
  name: 'CompletedLine',
  components: {

  },
  props: {
    line: {
      type: Object,
      required: true,
      default() {
        return {
          correct: true,
          text: '',
          blind: true,
        };
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    errorLine() {
      const lastLetter = this.line.text.substr(this.line.text.length - 1);
      const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      let errorPart = letters[Math.floor(Math.random() * letters.length)];
      if (lastLetter.toLowerCase() !== lastLetter) {
        errorPart = errorPart.toUpperCase();
      }
      return {
        correctPart: this.line.text.substring(0, this.line.text.length - 1),
        errorPart,
      };
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.strikethrough {
  text-decoration: underline;
}
</style>
