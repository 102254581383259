<template>
  <div :style="{ display: 'flex', flexDirection: 'column', alignItems: isInterruption ? 'center' : 'initial' }">
    <div v-for="(currentLine, index) in contentArray" :key="index">
      <p v-if="currentLine.type === 'text'">
        {{ currentLine.text }}
      </p>
      <v-btn v-if="currentLine.type === 'link' && !isInterruption" class="mb-3" :href="currentLine.url" target="_blank">
        {{ currentLine.text }}
      </v-btn>
      <v-img v-if="currentLine.type === 'img'" :src="currentLine.url" max-height="400" contain />
    </div>
  </div>
</template>

<script>
import tools from '../../tools';

export default {
  name: 'RichText',
  components: {},
  props: {
    contentString: {
      type: String,
      required: true,
      default: '',
    },
    isInterruption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    contentArray() {
      return this.isInterruption ? tools.getRestrictedParagraphs(this.contentString) : tools.getParagraphs(this.contentString);
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
