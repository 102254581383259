<template>
  <v-row>
    <v-col class="text-center pt-4">
      <br>
      <v-progress-circular
        indeterminate
        color="primary"
        :size="150"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      color: '',
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setSnackbar') {
        this.message = state.snackbar.text;
        this.color = state.snackbar.color;
        this.show = true;
      }
    });
  },
};
</script>
