<template>
  <div>
    <vue-headful
      :title="`Write for me!`"
      :description="`Report list`"
    />
    <p class="display-1">
      Reports
    </p>
    <reportList />
  </div>
</template>

<script>
// @ is an alias to /src
import ReportList from '../components/listViews/ReportList.vue';

export default {
  name: 'Reports',
  components: {
    ReportList,
  },
  data() {
    return {
      forumList: [],
    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
</script>
