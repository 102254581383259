<template>
  <div>
    <p
      v-if="callToAction && changed"
      class="d-block grey darken-3 pl-3"
    >
      Reset or confirm with the buttons below.
    </p>
    <p
      v-if="callToAction && !changed"
      class="d-block grey darken-3 pl-3"
    >
      Click to set your gender.
    </p>

    <div
      id="genderPicker"
      v-resize="resize"
      class="ma-0 pa-0"
    >
      <canvas
        id="genderCanvas"
        :class="{ crosshairs: !readOnly }"
        @click="select"
        @mousedown="startSearch"
        @mouseup="endSearch"
        @mouseleave="endSearch"
        @mousemove="drawSearchBox"
      />
      <v-col
        v-if="changed"
        class="text-right"
      >
        <v-btn
          x-small
          @click="resetValues"
        >
          reset
        </v-btn>
        <v-btn
          x-small
          color="primary"
          @click="saveValues"
        >
          save
        </v-btn>
      </v-col>
    </div>
  </div>
</template>

<script>
import apiClient from '../../apiClient';

export default {
  name: 'GenderPicker',
  components: {

  },
  props: {
    user: {
      type: Object,
      required: false,
      default() {
        return {
          genderX: 0.5,
          genderY: 0.5,
        };
      },
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    callToAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      x: 1,
      y: 1,
      savedX: 1,
      savedY: 1,
      startSearchX: 0,
      startSearchY: 1,
      searchStarted: false,
      endSearchX: 0,
      endSearchY: 1,
      changed: false,
      genderCTA: 'Click to set your gender.',
    };
  },
  watch: {
    user() {
      if (!this.search) {
        this.fillData();
      }
      this.resize();
    },
  },
  mounted() {
    if (!this.search) {
      this.fillData();
    }
    this.resize();
  },
  methods: {
    fillData() {
      if (this.user.genderX && this.user.genderY) {
        this.x = this.user.genderX;
        this.y = this.user.genderY;
        this.savedX = this.user.genderX;
        this.savedY = this.user.genderY;
      }
    },
    resize() {
      const r = document.getElementById('genderPicker');
      const c = document.getElementById('genderCanvas');

      c.width = r.clientWidth;
      c.height = r.clientWidth / 2;
      if (!this.search) {
        this.drawCrosshairs();
      }
    },
    select(e) {
      if (this.search || this.readOnly) {
        return;
      }
      this.changed = true;

      const rect = e.target.getBoundingClientRect();
      this.x = (e.clientX - rect.left) / rect.width;
      this.y = (e.clientY - rect.top) / rect.height;

      const refX = (this.x - 0.5) * 2;
      const refY = 1 - (Math.sqrt(1 - (refX * refX)));
      if (this.y < refY) {
        this.y = refY;
      }
      this.drawCrosshairs();
    },
    startSearch(e) {
      if (!this.search) {
        return;
      }
      const startPoint = this.getCoordinates(e);
      this.startSearchX = startPoint.x;
      this.startSearchY = startPoint.y;
      this.searchStarted = true;
    },
    endSearch(e) {
      if (!this.search || !this.searchStarted) {
        return;
      }
      const endPoint = this.getCoordinates(e);
      this.endSearchX = endPoint.x;
      this.endSearchY = endPoint.y;
      this.searchStarted = false;
      this.submitSearchResults();
    },
    getCoordinates(e) {
      const rect = e.target.getBoundingClientRect();
      const x = (e.clientX - rect.left) / rect.width;
      const y = (e.clientY - rect.top) / rect.height;

      return {
        x: this.round(x, 1),
        y: this.round(y, 1),
      };
    },
    drawSearchBox(e) {
      if (!this.search || !this.searchStarted) {
        return;
      }
      const c = document.getElementById('genderCanvas');
      const ctx = c.getContext('2d');
      const searchPoint = this.getCoordinates(e);
      // Clear Canvas
      ctx.clearRect(0, 0, c.width, c.height);
      ctx.beginPath();
      ctx.strokeStyle = this.$vuetify.theme.themes.dark.primary;
      const x = this.startSearchX * c.width;
      const y = this.startSearchY * c.height;
      const width = searchPoint.x * c.width - x;
      const height = searchPoint.y * c.height - y;
      // Draw Lines
      ctx.rect(x, y, width, height);
      ctx.stroke();
    },
    drawCrosshairs() {
      const c = document.getElementById('genderCanvas');
      const ctx = c.getContext('2d');

      // Clear Canvas
      ctx.clearRect(0, 0, c.width, c.height);

      ctx.beginPath();
      ctx.strokeStyle = this.$vuetify.theme.themes.dark.primary;

      // Draw Lines
      ctx.moveTo(this.x * c.width, 0);
      ctx.lineTo(this.x * c.width, c.height);
      ctx.moveTo(0, this.y * c.height);
      ctx.lineTo(c.width, this.y * c.height);
      ctx.stroke();
    },
    saveValues() {
      const reqObject = {
        genderX: this.x,
        genderY: this.y,
      };
      apiClient.post('setGender', reqObject)
        .then(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Gender saved successfully!!',
            color: 'success',
          });
          this.changed = false;
          this.savedX = this.x;
          this.savedY = this.y;
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Sorry, there was a problem setting your gender. Please try again later!',
            color: 'error',
          });
        });
    },
    resetValues() {
      this.x = this.savedX;
      this.y = this.savedY;
      if (!this.search) {
        this.drawCrosshairs();
      }
      this.changed = false;
    },
    round(value, precision) {
      const multiplier = 10 ** precision || 0;
      return Math.round(value * multiplier) / multiplier;
    },
    submitSearchResults() {
      const searchObject = {
        x: {
          from: this.startSearchX <= this.endSearchX ? this.startSearchX : this.endSearchX,
          to: this.startSearchX >= this.endSearchX ? this.startSearchX : this.endSearchX,
        },
        y: {
          from: this.startSearchY <= this.endSearchY ? this.startSearchY : this.endSearchY,
          to: this.startSearchY >= this.endSearchY ? this.startSearchY : this.endSearchY,
        },
      };
      this.$emit('genderSearchResult', searchObject);
    },
  },

};
</script>

<style scoped>

#genderPicker {
  background-color: light-grey;
  background-image: url('~@/assets/img/gender_bg.svg');
}

.crosshairs {
  cursor: cell;
}

</style>
